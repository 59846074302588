import { GoogleLogin } from '@react-oauth/google';
import { useEffect, useRef, useState } from 'react';

interface GoogleOAuthButtonProps {
  onSuccess: (response: any) => void;
  onError: () => void;
}

const GoogleOAuthButton: React.FC<GoogleOAuthButtonProps> = ({
  onSuccess,
  onError,
}) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const [isGoogleReady, setIsGoogleReady] = useState(false);

  useEffect(() => {
    // Check periodically for the Google button until it's found
    const interval = setInterval(() => {
      const googleButton = buttonRef.current?.querySelector('iframe');
      if (googleButton) {
        setIsGoogleReady(true);
        clearInterval(interval);
      }
    }, 100);

    // Cleanup the interval
    return () => clearInterval(interval);
  }, []);

  const handleCustomButtonClick = () => {
    // Find and click the hidden Google Login button
    const googleButton = buttonRef.current?.querySelector('div[role="button"]');
    if (googleButton) {
      // Create and dispatch a click event
      const clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      googleButton.dispatchEvent(clickEvent);
    } else {
      console.log('Google button not found', buttonRef.current?.innerHTML);
    }
  };

  const handleSuccess = (credentialResponse: any) => {
    onSuccess(credentialResponse);
  };

  const handleError = () => {
    console.log('Google login error');
    onError();
  };

  return (
    <div className="mt-6 grid grid-cols-1 gap-4">
      {/* Hidden Google Login component */}
      <div ref={buttonRef} className="hidden">
        <GoogleLogin
          onSuccess={handleSuccess}
          onError={handleError}
          useOneTap={false}
          type="standard"
          theme="outline"
          size="large"
          text="signin_with"
          shape="rectangular"
        />
      </div>

      {/* Custom styled button */}
      <button
        onClick={handleCustomButtonClick}
        type="button"
        disabled={!isGoogleReady}
        className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent dark:bg-black dark:text-white dark:ring-gray-600 dark:hover:bg-gray-900"
      >
        <svg viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5">
          <path
            d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
            fill="#EA4335"
          />
          <path
            d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
            fill="#4285F4"
          />
          <path
            d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
            fill="#FBBC05"
          />
          <path
            d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
            fill="#34A853"
          />
        </svg>
        <span className="text-sm font-semibold leading-6">Google</span>
      </button>
    </div>
  );
};

export default GoogleOAuthButton;
