import React, { useState, useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import logo from './assets/logo.png';
import { authService } from './ApiClients';
import {
  getToken,
  verifyToken,
  getUserName,
  clearToken,
  clearUserName,
  clearRememberMe,
  getRememberMe,
  getLoginRoute,
} from './TokenUtils';
import { useNavigate } from 'react-router-dom';
import GoogleOAuthButton from './GoogleOAuthButton';

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const GOOGLE_OAUTH_CLIENT_ID =
    '784874334013-sf3pm8m49rqmdmathapc3flni90bt7bh.apps.googleusercontent.com';

  useEffect(() => {
    const verifyAuth = async () => {
      let token = getToken();
      let userName = getUserName();
      let rememberMe = getRememberMe();
      if (!token) {
        setIsAuthenticated(false);
        clearToken();
        if (userName && rememberMe) {
          setRememberMe(true);
          setUsername(userName);
        } else {
          clearRememberMe();
          clearUserName();
        }
      } else {
        try {
          const verified = await verifyToken(token);
          if (verified) {
            const route = getLoginRoute();
            if (!route) {
              throw new Error('Failed to get route.');
            }
            window.location.href = route;
            return;
          }
          setIsAuthenticated(verified);
          if (userName && rememberMe) {
            setRememberMe(true);
            setUsername(userName);
          } else {
            setRememberMe(false);
            setUsername('');
          }
        } catch (error) {
          console.error('Token verification failed:', error);
          setIsAuthenticated(false);
        }
      }
    };

    verifyAuth();
  }, []);

  const validateEmail = (email: string): boolean => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password: string): boolean => {
    return password.length >= 6;
  };

  const saveToken = (token: string, refreshToken: string | undefined) => {
    if (rememberMe) {
      localStorage.setItem('token', token);
      localStorage.setItem('username', username);
      localStorage.setItem('rememberme', rememberMe.toString());
      if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
      }
    } else {
      clearToken();
      clearUserName();
      clearRememberMe();
      sessionStorage.setItem('token', token);
      sessionStorage.setItem('username', username);
      sessionStorage.setItem('rememberme', rememberMe.toString());
      if (refreshToken) {
        sessionStorage.setItem('refreshToken', refreshToken);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');

    if (!validateEmail(username)) {
      setError('Please enter a valid email address.');
      return;
    }

    if (!validatePassword(password)) {
      setError('Password must be at least 8 characters long.');
      return;
    }

    try {
      const response = await authService.login({
        username,
        password,
        grantType: 'password',
      });

      const responseData = response.data as {
        access_token?: string;
        refresh_token?: string;
      };

      if (!responseData.access_token) {
        throw new Error('Access token not found in response');
      }

      const token = responseData.access_token;
      const refreshToken = responseData.refresh_token;

      saveToken(token, refreshToken);

      const savedLocation = sessionStorage.getItem('returnTo');
      if (savedLocation) {
        const { pathname, search, hash } = JSON.parse(savedLocation);
        sessionStorage.removeItem('returnTo');
        navigate(pathname + search + hash);
      } else {
        navigate('/home');
      }
    } catch (error: any) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            setError('Invalid email or password. Please try again.');
            break;
          case 403:
            setError('Your account has been locked. Please contact support.');
            break;
          case 404:
            setError('Login service not found. Please try again later.');
            break;
          case 500:
            setError('Server error. Please try again later.');
            break;
          default:
            setError(
              `An error occurred (${error.response.status}). Please try again.`
            );
        }
      } else if (error.request) {
        setError(
          'No response from server. Please check your internet connection and try again.'
        );
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
      console.error('login failed: ', error);
    }
  };

  const handleGoogleSuccess = async (credentialResponse: any) => {
    try {
      const response = await authService.googleLogin({
        bodyGoogleLogin: {
          token: credentialResponse.credential,
        },
      });

      const responseData = response.data as {
        access_token?: string;
        refresh_token?: string;
      };

      if (!responseData.access_token) {
        throw new Error('Access token not found in response');
      }

      const token = responseData.access_token;
      const refreshToken = responseData.refresh_token;

      saveToken(token, refreshToken);

      const savedLocation = sessionStorage.getItem('returnTo');
      if (savedLocation) {
        const { pathname, search, hash } = JSON.parse(savedLocation);
        sessionStorage.removeItem('returnTo');
        navigate(pathname + search + hash);
      } else {
        navigate('/home');
      }
    } catch (error: any) {
      console.error('Google login failed:', error);
      if (error.response?.data?.detail) {
        setError(
          `Failed to authenticate with Google. ${error.response?.data?.detail}. Please try again.`
        );
      } else {
        setError('Failed to authenticate with Google. Please try again.');
      }
    }
  };

  const handleGoogleError = () => {
    setError('Google sign in was unsuccessful. Please try again.');
  };

  return (
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
      <div className="flex min-h-full flex-1 flex-col justify-center py-8 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            alt="Levittown"
            src={logo}
            className="mx-auto h-[100px] w-auto"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-stone-100 px-6 py-12 shadow sm:rounded-lg sm:px-12 dark:bg-gray-800">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    autoComplete="email"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6 dark:bg-gray-700 dark:text-white dark:ring-gray-600 dark:placeholder:text-gray-400"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6 dark:bg-gray-700 dark:text-white dark:ring-gray-600 dark:placeholder:text-gray-400"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-3 block text-sm leading-6 text-gray-900 dark:text-white"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm leading-6">
                  <a
                    href="/forgot-password"
                    className="font-semibold text-sky-600 hover:text-sky-500 dark:text-white"
                  >
                    Forgot password?
                  </a>
                </div>
              </div>

              <div>
                {error && (
                  <div
                    className="mb-4 p-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                    role="alert"
                  >
                    {error}
                  </div>
                )}
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-sky-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500"
                >
                  Sign in
                </button>
              </div>
            </form>

            <div>
              <div className="relative mt-10">
                <div
                  aria-hidden="true"
                  className="absolute inset-0 flex items-center"
                >
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-white px-6 text-gray-900">
                    Or continue with
                  </span>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-4">
                <GoogleOAuthButton
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleError}
                ></GoogleOAuthButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default LoginPage;
