import { useParams } from 'react-router-dom';
import AppSidebar from './AppSidebar';
import { Navbar } from './Components/Catalyst/navbar';
import { SidebarLayout } from './Components/Catalyst/sidebar-layout';
import { useEffect, useState } from 'react';
import { homeService } from './ApiClients';
import { HomeCreateOrUpdateSchema, HomeSchema } from './hoa-api-client';
import { Heading, Subheading } from './Components/Catalyst/heading';
import { Button } from './Components/Catalyst/button';
import { Divider } from './Components/Catalyst/divider';
import {
  Description,
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from './Components/Catalyst/fieldset';
import { Input } from './Components/Catalyst/input';
import { Dialog, DialogBody, DialogTitle } from './Components/Catalyst/dialog';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from './Components/Catalyst/dropdown';
import ViolationList from './ViolationList';
import LoadingSpinner from './LoadingSpinner';
import usStates from './utils/addressUtils';
import { formatPhoneNumber, stripPhoneFormatting } from './utils/phoneUtils';
import ShowAlert from './ShowAlert';

interface ContactInfoItem {
  name: string;
  render: (value: any) => React.ReactNode;
}

interface TouchedFields {
  [key: string]: boolean;
}

interface FormErrors {
  [key: string]: string;
}

const HomeDetailPage: React.FC = () => {
  const { hoaId, homeId } = useParams<{ hoaId: string; homeId: string }>();
  const [loading, setLoading] = useState(true);
  const [home, setHome] = useState<HomeSchema | null>(null);
  const [showSaveError, setShowSaveError] = useState(false);
  const [saveErrorTitle, setSaveErrorTitle] = useState<string>('');
  const [saveError, setSaveError] = useState<string>('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFetchError, setShowFetchError] = useState(false);
  const [fetchErrorTitle, setFetchErrorTitle] = useState<string>('');
  const [fetchError, setFetchError] = useState<string>('');
  const [isEditing, setIsEditing] = useState(false);
  const [editedHome, setEditedHome] = useState<HomeCreateOrUpdateSchema | null>(
    null
  );
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const [isSaving, setIsSaving] = useState(false);
  // const [currentOwner, setCurrentOwner] = useState<OwnerSchema>({
  //   first_name: '',
  //   last_name: '',
  // });

  const propertyTypes = [
    'Single Family',
    'Townhouse',
    'Condo',
    'Apartment',
    'Other',
  ];
  const statusOptions = [
    'Owner Occupied',
    'Renter Occupied',
    'Vacant',
    'For Sale',
    'For Rent',
    'Unknown',
  ];

  useEffect(() => {
    const fetchHoa = async () => {
      try {
        setLoading(true);
        const response = await homeService.getHomeByHoaAndId({
          hoaId: Number(hoaId),
          homeId: Number(homeId),
        });
        setHome(response.data);
      } catch (err) {
        console.error('Error fetching home:', err);
        let errorMessage = 'An unexpected error occurred while fetching home.';
        if (err instanceof Error) {
          errorMessage = err.message;
        } else if (typeof err === 'string') {
          errorMessage = err;
        }

        setFetchErrorTitle('Failed to Load Home Details');
        setFetchError(errorMessage);
        setShowFetchError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchHoa();
  }, [hoaId, homeId]);

  const handleEdit = () => {
    if (home) {
      const { id, date_created, date_updated, ...editableFields } = home;
      setEditedHome(editableFields as HomeCreateOrUpdateSchema);
      setIsEditing(true);
      setTouchedFields({});
      setFormErrors({});
    }
  };

  const handleSave = async () => {
    if (!editedHome || !home) return;

    setIsSaving(true);

    // Validate all fields before submitting
    const errors: FormErrors = {};
    Object.keys(editedHome).forEach((key) => {
      const error = validateField(key, (editedHome as any)[key]);
      if (error) {
        errors[key] = error;
      }
    });

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setIsSaving(false);
      return;
    }

    try {
      await homeService.updateHome({
        hoaId: Number(hoaId),
        homeId: home.id,
        homeCreateOrUpdateSchema: editedHome,
      });
      setHome({ ...home, ...editedHome });
      setIsEditing(false);
      setShowSuccessAlert(true);
    } catch (err) {
      console.error('Error updating Home:', err);
      let errorMessage = 'An unexpected error occurred while updating home.';
      if (err instanceof Error) {
        errorMessage = err.message;
      } else if (typeof err === 'string') {
        errorMessage = err;
      }

      setSaveErrorTitle('Failed to Update Home');
      setSaveError(errorMessage);
      setShowSaveError(true);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedHome(home);
    setFormErrors({});
    setTouchedFields({});
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string } }
  ) => {
    if (!editedHome) return;

    const { name, value } = e.target;

    if (name === 'phone') {
      const cleanedPhone = stripPhoneFormatting(value);
      setEditedHome((prev) => {
        if (!prev) return null;
        return {
          ...prev,
          [name]: cleanedPhone, // Store raw digits in state
        };
      });
      setTouchedFields((prev) => ({ ...prev, [name]: true }));

      const error = validateField(name as keyof HomeSchema, cleanedPhone);
      setFormErrors((prev) => {
        const newErrors = { ...prev };
        if (error) {
          newErrors[name as keyof FormErrors] = error;
        } else {
          delete newErrors[name as keyof FormErrors];
        }
        return newErrors;
      });
      return;
    }

    setEditedHome({ ...editedHome, [name]: value });
    setTouchedFields({ ...touchedFields, [name]: true });

    const error = validateField(name, value);
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (error) {
        newErrors[name] = error;
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  const validateField = (name: string, value: any): string => {
    switch (name) {
      case 'street':
      case 'city':
      case 'state':
      case 'zip':
        return !value
          ? `${name.charAt(0).toUpperCase() + name.slice(1)} is required`
          : '';
      case 'email':
        if (value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          return 'Invalid email format';
        }
        return '';
      case 'phone':
        if (value) {
          const digitsOnly = value.replace(/\D/g, '');
          return digitsOnly.length !== 10
            ? 'Phone number must be 10 digits'
            : '';
        }
        return '';
      case 'owners':
        if (!Array.isArray(value) || value.length === 0) {
          return 'At least one owner is required';
        }
        const invalidOwners = value.filter(
          (owner) => !owner.first_name || !owner.last_name
        );
        if (invalidOwners.length > 0) {
          return 'All owners must have both first and last names';
        }
        return '';
      case 'property_type':
      case 'status':
        return !value || value === 'Select Type' || value === 'Select Status'
          ? `${name.charAt(0).toUpperCase() + name.slice(1).replace('_', ' ')} is required`
          : '';
      default:
        return '';
    }
  };

  const handleDialogClose = () => {
    setEditedHome(home);
    setIsEditing(false);
    setFormErrors({});
    setTouchedFields({});
  };

  // const handleOwnerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setCurrentOwner((prev) => ({ ...prev, [name]: value }));
  // };

  // const addOwner = () => {
  //   if (currentOwner.first_name && currentOwner.last_name && editedHome) {
  //     setEditedHome((prev) => {
  //       if (!prev) return prev;
  //       const updatedOwners = [...prev.owners, currentOwner];
  //       const error = validateField('owners', updatedOwners);
  //       setFormErrors((prevErrors) => {
  //         if (error) {
  //           return { ...prevErrors, owners: error };
  //         } else {
  //           const { owners, ...rest } = prevErrors;
  //           return rest;
  //         }
  //       });
  //       return { ...prev, owners: updatedOwners };
  //     });
  //     setCurrentOwner({ first_name: '', last_name: '' });
  //   }
  // };

  // const removeOwner = (index: number) => {
  //   if (editedHome) {
  //     setEditedHome((prev) => {
  //       if (!prev) return prev;
  //       const updatedOwners = prev.owners.filter((_, i) => i !== index);
  //       const error = validateField('owners', updatedOwners);
  //       setFormErrors((prevErrors) => {
  //         if (error) {
  //           return { ...prevErrors, owners: error };
  //         } else {
  //           const { owners, ...rest } = prevErrors;
  //           return rest;
  //         }
  //       });
  //       return { ...prev, owners: updatedOwners };
  //     });
  //   }
  // };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setTouchedFields((prev) => ({ ...prev, [name]: true }));

    const error = validateField(name as keyof HomeCreateOrUpdateSchema, value);
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (error) {
        newErrors[name] = error;
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  const isFormValid = () => {
    // Check for any existing errors
    if (Object.keys(formErrors).length > 0) {
      return false;
    }

    // Required fields
    const requiredFields = [
      'street',
      'city',
      'state',
      'zip',
      'property_type',
      'status',
    ];

    return requiredFields.every((field) => {
      const value = editedHome?.[field as keyof HomeCreateOrUpdateSchema];
      // if (field === 'owners') {
      //   return editedHome?.owners !== undefined && editedHome.owners.length > 0;
      // }
      return value !== undefined && value !== '';
    });
  };

  if (loading) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  // const contactInfo: ContactInfoItem[] = [
  //   {
  //     name: 'Owners',
  //     render: (owners: OwnerSchema[]) => (
  //       <div className="break-words">
  //         {owners.map((owner, index) => (
  //           <div key={index} className="mb-1">
  //             {`${owner.first_name} ${owner.last_name}`}
  //           </div>
  //         ))}
  //       </div>
  //     ),
  //   },
  //   {
  //     name: 'Email',
  //     render: (email: string) => (
  //       <div className="break-words">{email || 'N/A'}</div>
  //     ),
  //   },
  //   {
  //     name: 'Phone',
  //     render: (phone: string) => <div>{phone || 'N/A'}</div>,
  //   },
  // ];

  const fullAddress = home?.street2
    ? `${home.street}, ${home.street2}`
    : home?.street;
  const cityStateZip = `${home?.city}, ${home?.state} ${home?.zip}`;

  return (
    <SidebarLayout
      sidebar={<AppSidebar currentPage="hoa_detail" />}
      navbar={<Navbar>{/* Your navbar content */}</Navbar>}
    >
      <div className="flex justify-between items-center">
        <Heading level={2}>{fullAddress ?? 'Unable to load address'}</Heading>
        <div>
          <Button
            color="sky"
            onClick={handleEdit}
            className="mr-2"
            disabled={!home}
          >
            Edit Home Details
          </Button>
        </div>
      </div>
      <Subheading>{cityStateZip}</Subheading>

      <Divider className="mt-4 mb-6" />

      <ShowAlert
        alertType="success"
        alertTitle="Success"
        alertMessage="Home updated successfully!"
        isVisible={showSuccessAlert}
        onClose={() => setShowSuccessAlert(false)}
        timeout={5000}
        isInline={true}
      />

      <ShowAlert
        alertType="error"
        alertTitle={fetchErrorTitle}
        alertMessage={fetchError}
        isVisible={showFetchError}
        onClose={() => setShowFetchError(false)}
        timeout={5000}
        isInline={true}
      />

      <div>
        <Heading
          level={4}
          className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
        >
          Contact Information
        </Heading>
        {/* {home && (
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            {contactInfo.map((item) => (
              <div
                key={item.name}
                className="overflow-hidden rounded-lg bg-stone-100 dark:bg-gray-600 px-2 py-5 shadow sm:p-4 transition-colors duration-200"
              >
                <dt className="truncate text-sm font-medium text-gray-900 dark:text-white">
                  {item.name}
                </dt>
                <dd className="mt-1 text-medium font-semibold tracking-tight text-gray-900 dark:text-white break-all">
                  {item.name === 'Owners'
                    ? item.render(home.owners)
                    : item.name === 'Email'
                      ? item.render(home.email)
                      : item.render(
                          home?.phone ? formatPhoneNumber(home.phone) : ''
                        )}
                </dd>
              </div>
            ))}
          </dl>
        )} */}
      </div>

      <Divider className="mt-4 mb-6" />

      <ViolationList
        hoaId={parseInt(hoaId ?? '0', 10)}
        homeId={homeId ? parseInt(homeId, 10) : undefined}
        title="Violations"
      ></ViolationList>

      <Dialog
        open={isEditing}
        onClose={handleDialogClose}
        className="w-full max-w-2xl mx-auto"
      >
        <div className="relative flex flex-col h-full">
          <div className="p-4 md:p-6">
            <DialogTitle className="text-xl font-semibold">
              Edit Home
            </DialogTitle>
            <button
              onClick={handleDialogClose}
              className="absolute right-4 top-4 p-2 hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-full transition-colors duration-150"
              aria-label="Close dialog"
            >
              <XMarkIcon className="h-5 w-5 text-zinc-600 dark:text-zinc-100" />
            </button>

            <Divider className="my-4" />

            <div className="mb-4">
              <ShowAlert
                alertType="error"
                alertTitle={saveErrorTitle}
                alertMessage={saveError}
                isVisible={showSaveError}
                onClose={() => setShowSaveError(false)}
                timeout={5000}
                isInline={true}
              />
            </div>

            <DialogBody>
              <Fieldset>
                <FieldGroup>
                  {/* Owners Section */}
                  {/* <Field>
                    <Label>Owners*</Label>
                    <Description>at least one is required</Description>
                    <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                      <div className="col-span-1 md:col-span-2">
                        <Input
                          name="first_name"
                          value={currentOwner.first_name}
                          onChange={handleOwnerChange}
                          placeholder="First Name"
                          className="w-full"
                        />
                      </div>
                      <div className="col-span-1 md:col-span-2">
                        <Input
                          name="last_name"
                          value={currentOwner.last_name}
                          onChange={handleOwnerChange}
                          placeholder="Last Name"
                          className="w-full"
                        />
                      </div>
                      <div className="col-span-1">
                        <Button
                          outline
                          onClick={addOwner}
                          type="button"
                          className="w-full"
                        >
                          Add
                        </Button>
                      </div>
                    </div>
                    {editedHome?.owners.map((owner, index) => (
                      <div
                        key={index}
                        className="flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0 sm:space-x-2 mb-2 mt-2"
                      >
                        <Label className="sm:mr-4 flex-grow">
                          {owner.first_name} {owner.last_name}
                        </Label>
                        <Button
                          onClick={() => removeOwner(index)}
                          type="button"
                          color="rose"
                          className="w-full sm:w-auto"
                        >
                          Remove
                        </Button>
                      </div>
                    ))}
                    {touchedFields.owners && formErrors.owners && (
                      <ErrorMessage>{formErrors.owners}</ErrorMessage>
                    )}
                  </Field> */}

                  {/* Address Fields */}
                  <div className="space-y-4">
                    <Field>
                      <Label htmlFor="street">Street*</Label>
                      <Input
                        id="street"
                        name="street"
                        value={editedHome?.street || ''}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter street address"
                        required
                        className="w-full"
                        data-invalid={touchedFields.street && formErrors.street}
                      />
                      {touchedFields.street && formErrors.street && (
                        <ErrorMessage>{formErrors.street}</ErrorMessage>
                      )}
                    </Field>

                    <Field>
                      <Label htmlFor="street2">Street 2</Label>
                      <Input
                        id="street2"
                        name="street2"
                        value={editedHome?.street2 || ''}
                        onChange={handleInputChange}
                        placeholder="Enter additional street information (optional)"
                        className="w-full"
                      />
                    </Field>
                  </div>

                  {/* City, State, ZIP */}
                  <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                    <Field className="col-span-1 md:col-span-2">
                      <Label htmlFor="city">City*</Label>
                      <Input
                        id="city"
                        name="city"
                        value={editedHome?.city}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter city"
                        required
                        className="w-full"
                        data-invalid={touchedFields.city && formErrors.city}
                      />
                      {touchedFields.city && formErrors.city && (
                        <ErrorMessage>{formErrors.city}</ErrorMessage>
                      )}
                    </Field>

                    <Field className="col-span-1">
                      <Label htmlFor="state">State*</Label>
                      <Dropdown>
                        <div className="w-full">
                          <DropdownButton
                            outline
                            className="w-full px-3 py-2 flex justify-between items-center text-left border border-gray-300 rounded-lg"
                          >
                            {editedHome?.state || 'State'}
                            <ChevronDownIcon className="h-5 w-5 ml-2" />
                          </DropdownButton>
                        </div>
                        <DropdownMenu className="w-full max-h-60 overflow-y-auto">
                          {usStates.map((state) => (
                            <DropdownItem
                              key={state.label}
                              onClick={() =>
                                handleInputChange({
                                  target: {
                                    name: 'state',
                                    value: state.value,
                                    type: 'change',
                                    checked: false,
                                    nodeName: 'INPUT',
                                  } as unknown as EventTarget &
                                    HTMLInputElement,
                                } as React.ChangeEvent<HTMLInputElement>)
                              }
                            >
                              {state.label}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      {touchedFields.state && formErrors.state && (
                        <ErrorMessage>{formErrors.state}</ErrorMessage>
                      )}
                    </Field>

                    <Field className="col-span-1 md:col-span-2">
                      <Label htmlFor="zip">ZIP Code*</Label>
                      <Input
                        id="zip"
                        name="zip"
                        value={editedHome?.zip}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter ZIP code"
                        required
                        className="w-full"
                        data-invalid={touchedFields.zip && formErrors.zip}
                      />
                      {touchedFields.zip && formErrors.zip && (
                        <ErrorMessage>{formErrors.zip}</ErrorMessage>
                      )}
                    </Field>
                  </div>

                  {/* Contact Information */}
                  {/* <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                    <Field className="col-span-1 md:col-span-3">
                      <Label htmlFor="email">Contact Email</Label>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        value={editedHome?.email || ''}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter contact email"
                        className="w-full"
                        data-invalid={touchedFields.email && formErrors.email}
                      />
                      {touchedFields.email && formErrors.email && (
                        <ErrorMessage>{formErrors.email}</ErrorMessage>
                      )}
                    </Field>

                    <Field className="col-span-1 md:col-span-2">
                      <Label htmlFor="phone">Contact Phone</Label>
                      <Input
                        id="phone"
                        name="phone"
                        type="tel"
                        value={
                          !isEditing
                            ? ''
                            : editedHome?.phone
                              ? formatPhoneNumber(editedHome.phone)
                              : ''
                        }
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter contact phone"
                        className="w-full"
                        data-invalid={touchedFields.phone && formErrors.phone}
                      />
                      {touchedFields.phone && formErrors.phone && (
                        <ErrorMessage>{formErrors.phone}</ErrorMessage>
                      )}
                    </Field>
                  </div> */}

                  {/* Property Type and Status */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <Field>
                      <Label htmlFor="property_type">Property Type*</Label>
                      <Dropdown>
                        <div className="w-full">
                          <DropdownButton
                            outline
                            className="w-full px-3 py-2 flex justify-between items-center text-left border border-gray-300 rounded-lg"
                          >
                            {editedHome?.property_type ||
                              'Select Property Type'}
                            <ChevronDownIcon className="h-5 w-5 ml-2" />
                          </DropdownButton>
                        </div>
                        <DropdownMenu className="w-full">
                          {[
                            'Single Family',
                            'Townhouse',
                            'Condo',
                            'Apartment',
                            'Other',
                          ].map((type) => (
                            <DropdownItem
                              key={type}
                              onClick={() =>
                                handleInputChange({
                                  target: {
                                    name: 'property_type',
                                    value: type,
                                    type: 'change',
                                    checked: false,
                                    nodeName: 'INPUT',
                                  } as unknown as EventTarget &
                                    HTMLInputElement,
                                } as React.ChangeEvent<HTMLInputElement>)
                              }
                            >
                              {type}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      {touchedFields.property_type &&
                        formErrors.property_type && (
                          <ErrorMessage>
                            {formErrors.property_type}
                          </ErrorMessage>
                        )}
                    </Field>

                    <Field>
                      <Label htmlFor="status">Status*</Label>
                      <Dropdown>
                        <div className="w-full">
                          <DropdownButton
                            outline
                            className="w-full px-3 py-2 flex justify-between items-center text-left border border-gray-300 rounded-lg"
                          >
                            {editedHome?.status || 'Select Status'}
                            <ChevronDownIcon className="h-5 w-5 ml-2" />
                          </DropdownButton>
                        </div>
                        <DropdownMenu className="w-full">
                          {[
                            'Owner Occupied',
                            'Renter Occupied',
                            'Vacant',
                            'For Sale',
                            'For Rent',
                            'Unknown',
                          ].map((status) => (
                            <DropdownItem
                              key={status}
                              onClick={() =>
                                handleInputChange({
                                  target: {
                                    name: 'status',
                                    value: status,
                                    type: 'change',
                                    checked: false,
                                    nodeName: 'INPUT',
                                  } as unknown as EventTarget &
                                    HTMLInputElement,
                                } as React.ChangeEvent<HTMLInputElement>)
                              }
                            >
                              {status}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      {touchedFields.status && formErrors.status && (
                        <ErrorMessage>{formErrors.status}</ErrorMessage>
                      )}
                    </Field>
                  </div>
                </FieldGroup>
              </Fieldset>
            </DialogBody>
          </div>

          <div className="sticky bottom-0 mt-auto border-t border-gray-200 dark:border-gray-700 bg-stone-100 dark:bg-gray-800 p-4 md:p-6">
            <div className="flex flex-col space-y-3">
              <Button
                onClick={handleSave}
                color="sky"
                disabled={isSaving || !isFormValid()}
                className="w-full disabled:opacity-50"
              >
                {isSaving ? 'Saving...' : 'Save'}
              </Button>
              <Button
                onClick={handleCancel}
                color="zinc"
                disabled={isSaving}
                className="w-full"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </SidebarLayout>
  );
};

export default HomeDetailPage;
