import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SidebarLayout } from './Components/Catalyst/sidebar-layout';
import AppSidebar from './AppSidebar';
import { Navbar } from './Components/Catalyst/navbar';
import { Heading, Subheading } from './Components/Catalyst/heading';
import { Divider } from './Components/Catalyst/divider';
import { HoaSchema } from './hoa-api-client';
import { hoaService } from './ApiClients';
import {
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from './Components/Catalyst/fieldset';
import { Textarea } from './Components/Catalyst/textarea';
import { Input } from './Components/Catalyst/input';
import { Button } from './Components/Catalyst/button';
import { Dialog, DialogBody, DialogTitle } from './Components/Catalyst/dialog';
import HomeList from './HomeList';
import ViolationList from './ViolationList';
import LoadingSpinner from './LoadingSpinner';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from './Components/Catalyst/dropdown';
import usStates from './utils/addressUtils';
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon';
import { formatPhoneNumber, stripPhoneFormatting } from './utils/phoneUtils';
import ShowAlert from './ShowAlert';

interface TouchedFields {
  [key: string]: boolean;
}

interface FormErrors {
  name?: string;
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  contact_phone?: string;
  contact_email?: string;
  website_url?: string;
}

const HOADetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [hoa, setHoa] = useState<HoaSchema | null>(null);
  const [loading, setLoading] = useState(true);
  const [showSaveError, setShowSaveError] = useState(false);
  const [saveErrorTitle, setSaveErrorTitle] = useState<string>('');
  const [saveError, setSaveError] = useState<string>('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFetchError, setShowFetchError] = useState(false);
  const [fetchErrorTitle, setFetchErrorTitle] = useState<string>('');
  const [fetchError, setFetchError] = useState<string>('');
  const [isEditing, setIsEditing] = useState(false);
  const [editedHoa, setEditedHoa] = useState<HoaSchema | null>(null);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchHoa = async () => {
      try {
        setLoading(true);
        const response = await hoaService.getHoaById({
          hoaId: Number(id),
        });
        setHoa(response.data);
      } catch (err) {
        console.error('Error fetching HOA:', err);
        let errorMessage = 'An unexpected error occurred while fetching HOA.';
        if (err instanceof Error) {
          errorMessage = err.message;
        } else if (typeof err === 'string') {
          errorMessage = err;
        }

        setFetchErrorTitle('Failed to Load HOA Details');
        setFetchError(errorMessage);
        setShowFetchError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchHoa();
  }, [id]);

  const handleEdit = () => {
    setEditedHoa(hoa);
    setIsEditing(true);
    setTouchedFields({});
    setFormErrors({});
  };

  const handleSave = async () => {
    if (!editedHoa) return;

    if (!isFormValid()) {
      const allTouched: TouchedFields = {};
      Object.keys(editedHoa).forEach((key) => {
        allTouched[key] = true;
      });
      setTouchedFields(allTouched);
      return;
    }

    setIsSaving(true);
    setShowSaveError(false);
    setShowSuccessAlert(false);

    try {
      const response = await hoaService.updateHoa({
        hoaId: editedHoa.id,
        hoaCreateOrUpdateSchema: editedHoa,
      });
      setHoa(response.data);
      setIsEditing(false);
      setShowSuccessAlert(true);
    } catch (err) {
      console.error('Error updating HOA:', err);
      let errorMessage = 'An unexpected error occurred while updating HOA.';
      if (err instanceof Error) {
        errorMessage = err.message;
      } else if (typeof err === 'string') {
        errorMessage = err;
      }

      setSaveErrorTitle('Failed to Update HOA');
      setSaveError(errorMessage);
      setShowSaveError(true);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedHoa(hoa);
    setFormErrors({});
    setTouchedFields({});
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!editedHoa) return;
    const { name, value } = e.target;

    if (name === 'contact_phone') {
      const cleanedPhone = stripPhoneFormatting(value);
      setEditedHoa((prev) => {
        if (!prev) return null;
        return {
          ...prev,
          [name]: cleanedPhone, // Store raw digits in state
        } as HoaSchema;
      });
      setTouchedFields((prev) => ({ ...prev, [name]: true }));

      const error = validateField(name as keyof HoaSchema, cleanedPhone);
      setFormErrors((prev) => {
        const newErrors = { ...prev };
        if (error) {
          newErrors[name as keyof FormErrors] = error;
        } else {
          delete newErrors[name as keyof FormErrors];
        }
        return newErrors;
      });
      return;
    }

    setEditedHoa((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        [name]: value,
      } as HoaSchema;
    });
    setTouchedFields((prev) => ({ ...prev, [name]: true }));

    const error = validateField(name as keyof HoaSchema, value);
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (error) {
        newErrors[name as keyof FormErrors] = error;
      } else {
        delete newErrors[name as keyof FormErrors];
      }
      return newErrors;
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!editedHoa) return;
    const { name, checked } = e.target;
    setEditedHoa((prev) => ({ ...prev, [name]: checked }) as HoaSchema);
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setTouchedFields((prev) => ({ ...prev, [name]: true }));

    const error = validateField(name as keyof HoaSchema, value);
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (error) {
        newErrors[name as keyof FormErrors] = error;
      } else {
        delete newErrors[name as keyof FormErrors];
      }
      return newErrors;
    });
  };

  const validateField = (
    name: keyof HoaSchema,
    value: any
  ): string | undefined => {
    switch (name) {
      case 'name':
        return !value ? 'Name is required' : undefined;
      case 'street':
        return !value ? 'Street address is required' : undefined;
      case 'city':
        return !value ? 'City is required' : undefined;
      case 'state':
        return !value ? 'State is required' : undefined;
      case 'zip':
        return !value
          ? 'ZIP code is required'
          : !/^\d{5}(-\d{4})?$/.test(value.toString())
            ? 'Invalid ZIP code format'
            : undefined;
      case 'contact_phone':
        const cleanedPhone = stripPhoneFormatting(value?.toString() || '');
        return !cleanedPhone
          ? 'Contact phone is required'
          : !/^\d{10,11}$/.test(cleanedPhone)
            ? 'Phone number must be 10 or 11 digits'
            : undefined;
      case 'contact_email':
        return !value
          ? 'Contact email is required'
          : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.toString())
            ? 'Invalid email format'
            : undefined;
      case 'website_url':
        if (!value) return undefined;
        try {
          new URL(value.toString());
          return undefined;
        } catch {
          return 'Invalid website URL format';
        }
      default:
        return undefined;
    }
  };

  const validateForm = (): boolean => {
    const errors: FormErrors = {};
    (Object.keys(editedHoa || {}) as Array<keyof HoaSchema>).forEach((key) => {
      const error = validateField(key, editedHoa?.[key]);
      if (error) {
        errors[key as keyof FormErrors] = error;
      }
    });
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const isFormValid = () => {
    // Check for any existing errors
    if (Object.keys(formErrors).length > 0) {
      return false;
    }

    // Required fields from HoaSchema
    const requiredFields = [
      'name',
      'street',
      'city',
      'state',
      'zip',
      'contact_phone',
      'contact_email',
    ];

    if (!editedHoa) return false;

    return requiredFields.every((field) => {
      const value = editedHoa[field as keyof HoaSchema];
      return value !== undefined && value !== '';
    });
  };

  const handleDialogClose = () => {
    setEditedHoa(hoa);
    setIsEditing(false);
    setFormErrors({});
    setTouchedFields({});
  };

  if (loading) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return (
    <SidebarLayout
      sidebar={<AppSidebar currentPage="hoa_detail" />}
      navbar={<Navbar>{/* Your navbar content */}</Navbar>}
    >
      <div className="flex justify-between items-center">
        <Heading level={2}>{hoa?.name}</Heading>
        <div>
          <Button
            color="sky"
            onClick={handleEdit}
            className="mr-2"
            disabled={!hoa}
          >
            Edit HOA Details
          </Button>
        </div>
      </div>

      {hoa?.description && (
        <Subheading className="mt-2">{hoa?.description}</Subheading>
      )}

      <Divider className="mt-4 mb-6" />

      <ShowAlert
        alertType="success"
        alertTitle="Success"
        alertMessage="HOA updated successfully!"
        isVisible={showSuccessAlert}
        onClose={() => setShowSuccessAlert(false)}
        timeout={5000}
        isInline={true}
      />

      <ShowAlert
        alertType="error"
        alertTitle={fetchErrorTitle}
        alertMessage={fetchError}
        isVisible={showFetchError}
        onClose={() => setShowFetchError(false)}
        timeout={5000}
        isInline={true}
      />

      <div>
        <Heading
          level={4}
          className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
        >
          Contact Information
        </Heading>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {[
            { name: 'Email', value: hoa?.contact_email },
            {
              name: 'Phone',
              value: hoa?.contact_phone
                ? formatPhoneNumber(hoa?.contact_phone)
                : '',
            },
            { name: 'Website', value: hoa?.website_url },
          ].map((item) => (
            <div
              key={item.name}
              className="overflow-hidden rounded-lg bg-stone-100 dark:bg-gray-600 px-2 py-5 shadow sm:p-4 transition-colors duration-200"
            >
              <dt className="truncate text-sm font-medium text-gray-900 dark:text-white">
                {item.name}
              </dt>
              <dd className="mt-1 text-medium font-semibold tracking-tight text-gray-900 dark:text-white break-all">
                {item.value || 'N/A'}
              </dd>
            </div>
          ))}
        </dl>
      </div>

      <Divider className="mt-4 mb-6" />

      {hoa && <HomeList hoaId={hoa?.id}></HomeList>}

      <Divider className="mt-4 mb-6" />

      {hoa && (
        <ViolationList hoaId={hoa?.id} title="All Violations"></ViolationList>
      )}

      <Dialog
        open={isEditing}
        onClose={handleDialogClose}
        className="w-full max-w-2xl mx-auto"
      >
        <div className="relative flex flex-col h-full">
          <div className="p-4 md:p-6">
            <DialogTitle className="text-xl font-semibold">
              Edit HOA
            </DialogTitle>
            <button
              onClick={handleDialogClose}
              className="absolute right-4 top-4 p-2 hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-full transition-colors duration-150"
              aria-label="Close dialog"
            >
              <XMarkIcon className="h-5 w-5 text-zinc-600 dark:text-zinc-100" />
            </button>

            <Divider className="my-4" />

            <div className="mb-4">
              <ShowAlert
                alertType="error"
                alertTitle={saveErrorTitle}
                alertMessage={saveError}
                isVisible={showSaveError}
                onClose={() => setShowSaveError(false)}
                timeout={5000}
                isInline={true}
              />
            </div>

            <DialogBody>
              <Fieldset>
                <FieldGroup>
                  {/* HOA Name and Active Status */}
                  <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                    <Field className="col-span-1 md:col-span-4">
                      <Label htmlFor="name">HOA Name*</Label>
                      <Input
                        id="name"
                        name="name"
                        value={editedHoa?.name}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter HOA name"
                        required
                        className="w-full"
                        data-invalid={touchedFields.name && formErrors.name}
                      />
                      {touchedFields.name && formErrors.name && (
                        <ErrorMessage>{formErrors.name}</ErrorMessage>
                      )}
                    </Field>
                    <Field className="col-span-1">
                      <div className="flex items-center h-10 mt-2">
                        <input
                          id="is_active"
                          type="checkbox"
                          name="is_active"
                          checked={editedHoa?.is_active}
                          onChange={handleCheckboxChange}
                          className="mr-2 h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                        />
                        <Label htmlFor="is_active">Is Active</Label>
                      </div>
                    </Field>
                  </div>

                  {/* Address Fields */}
                  <div className="space-y-4">
                    <Field>
                      <Label htmlFor="street">Street*</Label>
                      <Input
                        id="street"
                        name="street"
                        value={editedHoa?.street}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter street address"
                        required
                        className="w-full"
                        data-invalid={touchedFields.street && formErrors.street}
                      />
                      {touchedFields.street && formErrors.street && (
                        <ErrorMessage>{formErrors.street}</ErrorMessage>
                      )}
                    </Field>

                    <Field>
                      <Label htmlFor="street2">Street 2</Label>
                      <Input
                        id="street2"
                        name="street2"
                        value={editedHoa?.street2 || ''}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter additional street information (optional)"
                        className="w-full"
                      />
                    </Field>
                  </div>

                  {/* City, State, ZIP */}
                  <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                    <Field className="col-span-1 md:col-span-2">
                      <Label htmlFor="city">City*</Label>
                      <Input
                        id="city"
                        name="city"
                        value={editedHoa?.city}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter city"
                        required
                        className="w-full"
                        data-invalid={touchedFields.city && formErrors.city}
                      />
                      {touchedFields.city && formErrors.city && (
                        <ErrorMessage>{formErrors.city}</ErrorMessage>
                      )}
                    </Field>

                    <Field className="col-span-1">
                      <Label htmlFor="state">State*</Label>
                      <Dropdown>
                        <div className="w-full">
                          <DropdownButton
                            outline
                            className="w-full px-3 py-2 flex justify-between items-center text-left border border-gray-300 rounded-lg"
                          >
                            {editedHoa?.state || 'State'}
                            <ChevronDownIcon className="h-5 w-5 ml-2" />
                          </DropdownButton>
                        </div>
                        <DropdownMenu className="w-full max-h-60 overflow-y-auto">
                          {usStates.map((state) => (
                            <DropdownItem
                              key={state.label}
                              onClick={() =>
                                handleInputChange({
                                  target: {
                                    name: 'state',
                                    value: state.value,
                                    type: 'change',
                                    checked: false,
                                    nodeName: 'INPUT',
                                  } as unknown as EventTarget &
                                    HTMLInputElement,
                                } as React.ChangeEvent<HTMLInputElement>)
                              }
                            >
                              {state.label}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      {touchedFields.state && formErrors.state && (
                        <ErrorMessage>{formErrors.state}</ErrorMessage>
                      )}
                    </Field>

                    <Field className="col-span-1 md:col-span-2">
                      <Label htmlFor="zip">ZIP Code*</Label>
                      <Input
                        id="zip"
                        name="zip"
                        value={editedHoa?.zip}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter ZIP code"
                        required
                        className="w-full"
                        data-invalid={touchedFields.zip && formErrors.zip}
                      />
                      {touchedFields.zip && formErrors.zip && (
                        <ErrorMessage>{formErrors.zip}</ErrorMessage>
                      )}
                    </Field>
                  </div>

                  {/* Contact Information */}
                  <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                    <Field className="col-span-1 md:col-span-3">
                      <Label htmlFor="contact_email">Contact Email*</Label>
                      <Input
                        id="contact_email"
                        name="contact_email"
                        type="email"
                        value={editedHoa?.contact_email || ''}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter contact email"
                        className="w-full"
                        data-invalid={
                          touchedFields.contact_email &&
                          formErrors.contact_email
                        }
                      />
                      {touchedFields.contact_email &&
                        formErrors.contact_email && (
                          <ErrorMessage>
                            {formErrors.contact_email}
                          </ErrorMessage>
                        )}
                    </Field>

                    <Field className="col-span-1 md:col-span-2">
                      <Label htmlFor="contact_phone">Contact Phone*</Label>
                      <Input
                        id="contact_phone"
                        name="contact_phone"
                        type="tel"
                        value={
                          editedHoa?.contact_phone
                            ? formatPhoneNumber(editedHoa.contact_phone)
                            : ''
                        }
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter contact phone"
                        className="w-full"
                        data-invalid={
                          touchedFields.contact_phone &&
                          formErrors.contact_phone
                        }
                      />
                      {touchedFields.contact_phone &&
                        formErrors.contact_phone && (
                          <ErrorMessage>
                            {formErrors.contact_phone}
                          </ErrorMessage>
                        )}
                    </Field>
                  </div>

                  {/* Website */}
                  <Field>
                    <Label htmlFor="website_url">Website URL</Label>
                    <Input
                      id="website_url"
                      name="website_url"
                      type="url"
                      value={editedHoa?.website_url || ''}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Enter website URL"
                      className="w-full"
                      data-invalid={
                        touchedFields.website_url && formErrors.website_url
                      }
                    />
                    {touchedFields.website_url && formErrors.website_url && (
                      <ErrorMessage>{formErrors.website_url}</ErrorMessage>
                    )}
                  </Field>

                  {/* Description */}
                  <Field>
                    <Label htmlFor="description">Description</Label>
                    <Textarea
                      id="description"
                      name="description"
                      value={editedHoa?.description || ''}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="500 character limit"
                      rows={3}
                      maxLength={500}
                      className="w-full"
                    />
                  </Field>
                </FieldGroup>
              </Fieldset>
            </DialogBody>
          </div>

          <div className="sticky bottom-0 mt-auto border-t border-gray-200 dark:border-gray-700 bg-stone-100 dark:bg-gray-800 p-4 md:p-6">
            <div className="flex flex-col space-y-3">
              <Button
                onClick={handleSave}
                color="sky"
                disabled={!isFormValid() || isSaving}
                className="w-full disabled:opacity-50"
              >
                {isSaving ? 'Saving...' : 'Save'}
              </Button>
              <Button
                onClick={handleCancel}
                color="zinc"
                disabled={isSaving}
                className="w-full"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </SidebarLayout>
  );
};

export default HOADetailPage;
