import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AppSidebar from './AppSidebar';
import { Navbar } from './Components/Catalyst/navbar';
import { SidebarLayout } from './Components/Catalyst/sidebar-layout';
import { Heading } from './Components/Catalyst/heading';
import { Divider } from './Components/Catalyst/divider';
import { Button } from './Components/Catalyst/button';
import { Dialog, DialogBody, DialogTitle } from './Components/Catalyst/dialog';
import {
  Field,
  FieldGroup,
  Fieldset,
  Label,
  ErrorMessage,
} from './Components/Catalyst/fieldset';
import { Input } from './Components/Catalyst/input';
import { violationService } from './ApiClients';
import { ViolationRead, ViolationUpdate } from './vio-api-client';
import { formatDate } from './utils/dateUtils';
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from './Components/Catalyst/dropdown';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Textarea } from './Components/Catalyst/textarea';
import ShowAlert from './ShowAlert';
import LoadingSpinner from './LoadingSpinner';

interface TouchedFields {
  [key: string]: boolean;
}

interface FormErrors {
  [key: string]: string;
}

const ViolationDetailPage: React.FC = () => {
  const { violationId } = useParams<{
    violationId: string;
  }>();
  const [loading, setLoading] = useState(true);
  const [violation, setViolation] = useState<ViolationRead | null>(null);
  const [showSaveError, setShowSaveError] = useState(false);
  const [saveErrorTitle, setSaveErrorTitle] = useState<string>('');
  const [saveError, setSaveError] = useState<string>('');

  const [showFetchError, setShowFetchError] = useState(false);
  const [fetchErrorTitle, setFetchErrorTitle] = useState<string>('');
  const [fetchError, setFetchError] = useState<string>('');
  const [isEditing, setIsEditing] = useState(false);
  const [editedViolation, setEditedViolation] =
    useState<ViolationUpdate | null>(null);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const severityOptions = ['Minor', 'Moderate', 'Major', 'Critical'];
  const statusOptions = [
    'Pending',
    'In Progress',
    'Resolved',
    'Escalated',
    'Appealed',
  ];
  const typeOptions = [
    'Maintenance',
    'Noise',
    'Architectural',
    'Parking',
    'Landscaping',
    'Pets',
    'Trash',
    'Other',
  ];

  useEffect(() => {
    const fetchViolation = async () => {
      try {
        setLoading(true);
        const response = await violationService.getViolationById({
          violationId: Number(violationId),
        });
        setViolation(response.data);
      } catch (err) {
        console.error('Error fetching violation:', err);
        let errorMessage =
          'An unexpected error occurred while fetching violation.';
        if (err instanceof Error) {
          errorMessage = err.message;
        } else if (typeof err === 'string') {
          errorMessage = err;
        }

        setFetchErrorTitle('Failed to Load Violation Details');
        setFetchError(errorMessage);
        setShowFetchError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchViolation();
  }, [violationId]);

  const handleEdit = () => {
    if (violation) {
      const {
        id,
        date_created,
        date_updated,
        home_id,
        created_by,
        ...editableFields
      } = violation;
      setEditedViolation(editableFields as ViolationUpdate);
      setIsEditing(true);
      setTouchedFields({});
      setFormErrors({});
    }
  };

  const isValidUrl = (string: string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleSave = async () => {
    if (!editedViolation || !violation) return;

    setIsSaving(true);
    setShowSaveError(false);
    setShowSuccessAlert(false);

    // Create a clean update payload
    const updatePayload: ViolationUpdate = {};

    // Only include fields that have changed
    if (editedViolation.violation_type !== violation.violation_type) {
      updatePayload.violation_type =
        editedViolation.violation_type?.toLowerCase();
    }

    if (editedViolation.severity !== violation.severity) {
      updatePayload.severity = editedViolation.severity?.toLowerCase();
    }

    if (editedViolation.description !== violation.description) {
      updatePayload.description = editedViolation.description;
    }

    if (editedViolation.message !== violation.message) {
      updatePayload.message = editedViolation.message;
    }

    if (editedViolation.status !== violation.status) {
      updatePayload.status = editedViolation.status
        ?.toLowerCase()
        .replace(' ', '_');
    }

    // Handle dates
    if (editedViolation.due_date) {
      updatePayload.due_date = new Date(editedViolation.due_date).toISOString();
    }

    if (editedViolation.follow_up_date) {
      updatePayload.follow_up_date = new Date(
        editedViolation.follow_up_date
      ).toISOString();
    }

    if (editedViolation.fine_paid_date) {
      updatePayload.fine_paid_date = new Date(
        editedViolation.fine_paid_date
      ).toISOString();
    }

    // Handle URLs - only include if they've changed and are valid URLs
    if (
      editedViolation.image_url &&
      editedViolation.image_url !== violation.image_url
    ) {
      try {
        new URL(editedViolation.image_url); // validate URL
        updatePayload.image_url = editedViolation.image_url;
      } catch (e) {
        console.error('Invalid image URL');
      }
    }

    if (
      editedViolation.document_url &&
      editedViolation.document_url !== violation.document_url
    ) {
      try {
        new URL(editedViolation.document_url); // validate URL
        updatePayload.document_url = editedViolation.document_url;
      } catch (e) {
        console.error('Invalid document URL');
      }
    }

    // Handle numeric values
    if (editedViolation.fine_amount !== violation.fine_amount) {
      updatePayload.fine_amount = Number(editedViolation.fine_amount);
    }

    try {
      const response = await violationService.updateViolation({
        violationId: Number(violationId),
        violationUpdate: updatePayload,
      });

      // Update local state
      setViolation(response.data);

      setIsEditing(false);
      setSuccessMessage('Violation updated successfully!');
      setShowSuccessAlert(true);
    } catch (err) {
      console.error('Error updating violation:', err);
      let errorMessage =
        'An unexpected error occurred while updating violation.';
      if (err instanceof Error) {
        errorMessage = err.message;
      } else if (typeof err === 'string') {
        errorMessage = err;
      }

      setSaveErrorTitle('Failed to Update Violation');
      setSaveError(errorMessage);
      setShowSaveError(true);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedViolation(null);
    setFormErrors({});
    setTouchedFields({});
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      | { target: { name: string; value: string } }
  ) => {
    if (!editedViolation) return;
    const { name, value } = e.target;

    if (name === 'fine_amount') {
      // Remove any non-digit characters except decimal point
      const numericValue = value.replace(/[^\d.]/g, '');
      // Ensure only valid decimal numbers
      if (numericValue === '' || /^\d*\.?\d{0,2}$/.test(numericValue)) {
        setEditedViolation((prev) => ({
          ...prev,
          [name]: numericValue === '' ? null : parseFloat(numericValue),
        }));
      }
    } else {
      setEditedViolation((prev) => ({ ...prev, [name]: value }));
    }

    setTouchedFields((prev) => ({ ...prev, [name]: true }));

    const error = validateField(name as keyof ViolationUpdate, value);
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (error) {
        newErrors[name] = error;
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  const handleBlur = (
    e: React.FocusEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setTouchedFields((prev) => ({ ...prev, [name]: true }));

    const error = validateField(name as keyof ViolationUpdate, value);
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (error) {
        newErrors[name] = error;
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  const isFormValid = () => {
    // Check for any existing errors
    if (Object.keys(formErrors).length > 0) {
      return false;
    }

    // Check required fields
    const requiredFields = [
      'violation_type',
      'severity',
      'description',
      'message',
    ];

    return requiredFields.every((field) => {
      const value = editedViolation?.[field as keyof ViolationUpdate];
      // Check if the field has a value and it's not the default placeholder
      if (!value) return false;
      if (field === 'violation_type' && value === 'Select Type') return false;
      if (field === 'severity' && value === 'Select Severity') return false;
      return true;
    });
  };

  const isValidDate = (dateString: string | null | undefined): boolean => {
    if (!dateString) return true; // null/undefined is OK for optional dates
    // Check if it's a complete date string (YYYY-MM-DD)
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateRegex.test(dateString)) return false;

    // Check if it's a valid date (not like 2024-13-45)
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date.getTime());
  };

  const validateField = (name: keyof ViolationUpdate, value: any): string => {
    switch (name) {
      case 'violation_type':
        return !typeOptions.includes(value)
          ? 'Please select a valid violation type'
          : '';
      case 'severity':
        return !severityOptions.includes(value)
          ? 'Please select a valid severity level'
          : '';
      case 'status':
        return !statusOptions.includes(value)
          ? 'Please select a valid status'
          : '';
      case 'description':
        return !value
          ? 'Description is required'
          : value.length > 300 // Changed from 500 to 300
            ? 'Description must be less than 300 characters'
            : '';
      case 'message':
        return !value ? 'Message is required' : '';
      case 'fine_amount':
        if (!value) return '';
        if (isNaN(value) || Number(value) < 0) {
          return 'Fine amount must be a positive number';
        }
        if (value > 10000) {
          return 'Fine amount cannot exceed $10,000.00';
        }
        return '';
      case 'due_date':
      case 'follow_up_date':
      case 'fine_paid_date':
      case 'resolution_date':
        return !isValidDate(value) ? 'Please enter a valid date' : '';
      case 'image_url':
      case 'document_url':
        if (!value) return '';
        return !isValidUrl(value)
          ? 'Please enter a valid URL (e.g., https://example.com)'
          : '';
      default:
        return '';
    }
  };

  if (loading) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return (
    <SidebarLayout
      sidebar={<AppSidebar currentPage={'violations'} />}
      navbar={<Navbar>{/* Your navbar content */}</Navbar>}
    >
      <div className="flex justify-between items-center mb-6">
        <Heading level={2}>Violation Details</Heading>
        <div>
          <Button color="sky" onClick={handleEdit} className="mr-2">
            Edit Violation
          </Button>
        </div>
      </div>
      <Divider className="mt-4 mb-6" />

      <ShowAlert
        alertType="success"
        alertTitle="Success"
        alertMessage={successMessage}
        isVisible={showSuccessAlert}
        onClose={() => setShowSuccessAlert(false)}
        timeout={5000}
        isInline={true}
      />

      <ShowAlert
        alertType="error"
        alertTitle={fetchErrorTitle}
        alertMessage={fetchError}
        isVisible={showFetchError}
        onClose={() => setShowFetchError(false)}
        timeout={5000}
        isInline={true}
      />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Basic Information */}
        <div className="bg-slate-100 dark:bg-gray-600 p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
            Basic Information
          </h3>
          <dl className="space-y-4">
            <div className="flex justify-between items-center">
              <dt className="text-sm font-semibold dark:text-white text-gray-900">
                Type
              </dt>
              <dd className="text-sm text-gray-900 dark:text-white">
                {violation?.violation_type}
              </dd>
            </div>
            <div className="flex justify-between items-center">
              <dt className="text-sm font-semibold dark:text-white text-gray-900">
                Severity
              </dt>
              <dd className="text-sm text-gray-900 dark:text-white">
                {violation?.severity}
              </dd>
            </div>
            <div className="flex justify-between items-center">
              <dt className="text-sm font-semibold dark:text-white text-gray-900">
                Status
              </dt>
              <dd className="text-sm text-gray-900 dark:text-white">
                {violation?.status}
              </dd>
            </div>
            <div className="flex flex-col">
              <dt className="text-sm font-semibold dark:text-white text-gray-900 mb-2">
                Description
              </dt>
              <dd className="text-sm text-gray-900 dark:text-white">
                {violation?.description}
              </dd>
            </div>
            <div className="flex flex-col">
              <dt className="text-sm font-semibold dark:text-white text-gray-900 mb-2">
                Message
              </dt>
              <dd className="text-sm text-gray-900 dark:text-white">
                {violation?.message}
              </dd>
            </div>
          </dl>
        </div>

        {/* Dates */}
        <div className="bg-slate-100 dark:bg-gray-600 p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
            Important Dates
          </h3>
          <dl className="space-y-4">
            <div className="flex justify-between items-center">
              <dt className="text-sm font-semibold dark:text-white text-gray-900">
                Date of Violation
              </dt>
              <dd className="text-sm text-gray-900 dark:text-white">
                {formatDate(violation?.date_of_violation)}
              </dd>
            </div>
            <div className="flex justify-between items-center">
              <dt className="text-sm font-semibold dark:text-white text-gray-900">
                Due Date
              </dt>
              <dd className="text-sm text-gray-900 dark:text-white">
                {formatDate(violation?.due_date)}
              </dd>
            </div>
            <div className="flex justify-between items-center">
              <dt className="text-sm font-semibold dark:text-white text-gray-900">
                Resolution Date
              </dt>
              <dd className="text-sm text-gray-900 dark:text-white">
                {formatDate(violation?.resolution_date)}
              </dd>
            </div>
            <div className="flex justify-between items-center">
              <dt className="text-sm font-semibold dark:text-white text-gray-900">
                Follow-up Date
              </dt>
              <dd className="text-sm text-gray-900 dark:text-white">
                {formatDate(violation?.follow_up_date)}
              </dd>
            </div>
            <div className="flex justify-between items-center">
              <dt className="text-sm font-semibold dark:text-white text-gray-900">
                Created Date
              </dt>
              <dd className="text-sm text-gray-900 dark:text-white">
                {formatDate(violation?.date_created)}
              </dd>
            </div>
            <div className="flex justify-between items-center">
              <dt className="text-sm font-semibold dark:text-white text-gray-900">
                Last Updated
              </dt>
              <dd className="text-sm text-gray-900 dark:text-white">
                {formatDate(violation?.date_updated)}
              </dd>
            </div>
          </dl>
        </div>

        {/* Fine Information */}
        <div className="bg-slate-100 dark:bg-gray-600 p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
            Fine Information
          </h3>
          <dl className="space-y-4">
            <div className="flex justify-between items-center">
              <dt className="text-sm font-semibold dark:text-white text-gray-900">
                Fine Amount
              </dt>
              <dd className="text-sm text-gray-900 dark:text-white">
                {violation?.fine_amount
                  ? `$${violation.fine_amount.toFixed(2)}`
                  : 'N/A'}
              </dd>
            </div>
            <div className="flex justify-between items-center">
              <dt className="text-sm font-semibold dark:text-white text-gray-900">
                Fine Paid Date
              </dt>
              <dd className="text-sm text-gray-900 dark:text-white">
                {formatDate(violation?.fine_paid_date)}
              </dd>
            </div>
          </dl>
        </div>

        {/* Additional Information */}
        <div className="bg-slate-100 dark:bg-gray-600 p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
            Additional Information
          </h3>
          <dl className="space-y-4">
            {/* <div className="flex justify-between items-center">
              <dt className="text-sm font-semibold dark:text-white text-gray-900">
                HOA ID
              </dt>
              <dd className="text-sm text-gray-900 dark:text-white">
                {violation?.hoa_id}
              </dd>
            </div> */}
            <div className="flex justify-between items-center">
              <dt className="text-sm font-semibold dark:text-white text-gray-900">
                Home ID
              </dt>
              <dd className="text-sm text-gray-900 dark:text-white">
                {violation?.home_id}
              </dd>
            </div>
            <div className="flex justify-between items-center">
              <dt className="text-sm font-semibold dark:text-white text-gray-900">
                Created By
              </dt>
              <dd className="text-sm text-gray-900 dark:text-white">
                {violation?.created_by}
              </dd>
            </div>
            <div className="flex justify-between items-center">
              <dt className="text-sm font-semibold dark:text-white text-gray-900">
                Updated By
              </dt>
              <dd className="text-sm text-gray-900 dark:text-white">
                {violation?.updated_by || 'N/A'}
              </dd>
            </div>
            {violation?.image_url && (
              <div className="flex flex-col">
                <dt className="text-sm font-semibold dark:text-white text-gray-900 mb-2">
                  Image URL
                </dt>
                <dd className="text-sm text-gray-900 dark:text-white break-all">
                  {violation.image_url}
                </dd>
              </div>
            )}
            {violation?.document_url && (
              <div className="flex flex-col">
                <dt className="text-sm font-semibold dark:text-white text-gray-900 mb-2">
                  Document URL
                </dt>
                <dd className="text-sm text-gray-900 dark:text-white break-all">
                  {violation.document_url}
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>

      <Dialog
        open={isEditing}
        onClose={() => setIsEditing(false)}
        className="w-full max-w-2xl mx-auto"
      >
        <div className="relative flex flex-col h-full">
          <div className="p-4 md:p-6">
            <DialogTitle className="text-xl font-semibold">
              Edit Violation
            </DialogTitle>
            <button
              onClick={() => setIsEditing(false)}
              className="absolute right-4 top-4 p-2 hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-full transition-colors duration-150"
              aria-label="Close dialog"
            >
              <XMarkIcon className="h-5 w-5 text-zinc-600 dark:text-zinc-100" />
            </button>

            <Divider className="my-4" />

            <div className="mb-4">
              <ShowAlert
                alertType="error"
                alertTitle={saveErrorTitle}
                alertMessage={saveError}
                isVisible={showSaveError}
                onClose={() => setShowSaveError(false)}
                timeout={5000}
                isInline={true}
              />
            </div>

            <DialogBody>
              <Fieldset>
                <FieldGroup>
                  {/* Type, Severity, and Status - Stack on mobile */}
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <Field>
                      <Label>Violation Type*</Label>
                      <Dropdown>
                        <div className="w-full">
                          <DropdownButton
                            outline
                            className="w-full px-3 py-2 flex justify-between items-center text-left border border-gray-300 rounded-lg"
                          >
                            {editedViolation?.violation_type || 'Select Type'}
                            <ChevronDownIcon className="h-5 w-5 ml-2" />
                          </DropdownButton>
                        </div>
                        <DropdownMenu className="w-full">
                          {typeOptions.map((type) => (
                            <DropdownItem
                              key={type}
                              onClick={() =>
                                handleInputChange({
                                  target: {
                                    name: 'violation_type',
                                    value: type,
                                    type: 'change',
                                    checked: false,
                                    nodeName: 'INPUT',
                                  } as unknown as EventTarget &
                                    HTMLInputElement,
                                } as React.ChangeEvent<HTMLInputElement>)
                              }
                            >
                              {type}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      {touchedFields.violation_type &&
                        formErrors.violation_type && (
                          <ErrorMessage>
                            {formErrors.violation_type}
                          </ErrorMessage>
                        )}
                    </Field>

                    <Field>
                      <Label>Severity*</Label>
                      <Dropdown>
                        <div className="w-full">
                          <DropdownButton
                            outline
                            className="w-full px-3 py-2 flex justify-between items-center text-left border border-gray-300 rounded-lg"
                          >
                            {editedViolation?.severity || 'Select Severity'}
                            <ChevronDownIcon className="h-5 w-5 ml-2" />
                          </DropdownButton>
                        </div>
                        <DropdownMenu className="w-full">
                          {severityOptions.map((severity) => (
                            <DropdownItem
                              key={severity}
                              onClick={() =>
                                handleInputChange({
                                  target: {
                                    name: 'severity',
                                    value: severity,
                                    type: 'change',
                                    checked: false,
                                    nodeName: 'INPUT',
                                  } as unknown as EventTarget &
                                    HTMLInputElement,
                                } as React.ChangeEvent<HTMLInputElement>)
                              }
                            >
                              {severity}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      {touchedFields.severity && formErrors.severity && (
                        <ErrorMessage>{formErrors.severity}</ErrorMessage>
                      )}
                    </Field>

                    <Field>
                      <Label>Status*</Label>
                      <Dropdown>
                        <div className="w-full">
                          <DropdownButton
                            outline
                            className="w-full px-3 py-2 flex justify-between items-center text-left border border-gray-300 rounded-lg"
                          >
                            {editedViolation?.status || 'Select Status'}
                            <ChevronDownIcon className="h-5 w-5 ml-2" />
                          </DropdownButton>
                        </div>
                        <DropdownMenu className="w-full">
                          {statusOptions.map((status) => (
                            <DropdownItem
                              key={status}
                              onClick={() =>
                                handleInputChange({
                                  target: {
                                    name: 'status',
                                    value: status,
                                    type: 'change',
                                    checked: false,
                                    nodeName: 'INPUT',
                                  } as unknown as EventTarget &
                                    HTMLInputElement,
                                } as React.ChangeEvent<HTMLInputElement>)
                              }
                            >
                              {status}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      {touchedFields.status && formErrors.status && (
                        <ErrorMessage>{formErrors.status}</ErrorMessage>
                      )}
                    </Field>
                  </div>

                  {/* Fine Amount and Due Date */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <Field>
                      <Label htmlFor="fine_amount">Fine Amount</Label>
                      <Input
                        id="fine_amount"
                        name="fine_amount"
                        type="number"
                        min="0"
                        step="0.01"
                        value={editedViolation?.fine_amount || ''}
                        onChange={handleInputChange}
                        placeholder="Enter fine amount (optional)"
                        className="w-full"
                      />
                      {touchedFields.fine_amount && formErrors.fine_amount && (
                        <ErrorMessage>{formErrors.fine_amount}</ErrorMessage>
                      )}
                    </Field>

                    <Field>
                      <Label htmlFor="due_date">Due Date</Label>
                      <Input
                        id="due_date"
                        name="due_date"
                        type="date"
                        value={editedViolation?.due_date?.split('T')[0] || ''}
                        onChange={handleInputChange}
                        className="w-full"
                      />
                    </Field>
                  </div>

                  {/* Follow-up Date and Fine Paid Date */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <Field>
                      <Label>Follow-up Date</Label>
                      <Input
                        type="date"
                        name="follow_up_date"
                        value={
                          editedViolation?.follow_up_date?.split('T')[0] || ''
                        }
                        onChange={handleInputChange}
                        className="w-full"
                      />
                    </Field>

                    <Field>
                      <Label>Fine Paid Date</Label>
                      <Input
                        type="date"
                        name="fine_paid_date"
                        value={
                          editedViolation?.fine_paid_date?.split('T')[0] || ''
                        }
                        onChange={handleInputChange}
                        className="w-full"
                      />
                    </Field>
                  </div>

                  {/* Description and Message */}
                  <div className="space-y-4">
                    <Field>
                      <Label htmlFor="description">Description*</Label>
                      <Textarea
                        id="description"
                        name="description"
                        value={editedViolation?.description ?? ''}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="300 character limit"
                        required
                        className="w-full"
                        data-invalid={
                          touchedFields.description && formErrors.description
                        }
                        rows={2}
                        maxLength={300}
                      />
                      {touchedFields.description && formErrors.description && (
                        <ErrorMessage>{formErrors.description}</ErrorMessage>
                      )}
                    </Field>

                    <Field>
                      <Label htmlFor="message">Detailed Message*</Label>
                      <Textarea
                        id="message"
                        name="message"
                        value={editedViolation?.message ?? ''}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Detailed description of the violation"
                        required
                        className="w-full"
                        data-invalid={
                          touchedFields.message && formErrors.message
                        }
                        rows={4}
                      />
                      {touchedFields.message && formErrors.message && (
                        <ErrorMessage>{formErrors.message}</ErrorMessage>
                      )}
                    </Field>
                  </div>
                </FieldGroup>
              </Fieldset>
            </DialogBody>
          </div>

          <div className="sticky bottom-0 mt-auto border-t border-gray-200 dark:border-gray-700 bg-stone-100 dark:bg-gray-800 p-4 md:p-6">
            <div className="flex flex-col space-y-3">
              <Button
                onClick={handleSave}
                color="sky"
                disabled={isSaving || !isFormValid()}
                className="w-full disabled:opacity-50"
              >
                {isSaving ? 'Saving...' : 'Save'}
              </Button>
              <Button
                onClick={handleCancel}
                color="zinc"
                disabled={isSaving}
                className="w-full"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </SidebarLayout>
  );
};

export default ViolationDetailPage;
