import React, { useState, useEffect } from 'react';
import { Heading } from './Components/Catalyst/heading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './Components/Catalyst/table';
import { HomeSchema, HomeCreateOrUpdateSchema } from './hoa-api-client';
import { homeService } from './ApiClients';
import { Input, InputGroup } from './Components/Catalyst/input';
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Button } from './Components/Catalyst/button';
import { Dialog, DialogTitle, DialogBody } from './Components/Catalyst/dialog';
import {
  Description,
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from './Components/Catalyst/fieldset';
import { useNavigate } from 'react-router-dom';
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from './Components/Catalyst/dropdown';
import { Divider } from './Components/Catalyst/divider';
import usStates from './utils/addressUtils';
import ShowAlert from './ShowAlert';
import LoadingSpinner from './LoadingSpinner';
import { formatPhoneNumber } from './utils/phoneUtils';

interface FormErrors {
  [key: string]: string | undefined;
}

const initialHomeState: HomeCreateOrUpdateSchema = {
  hoa_id: 0,
  street: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
  country: 'USA',
  property_type: 'Select Type',
  status: 'Select Status',
};

interface TouchedFields {
  [key: string]: boolean;
}

const HomeList: React.FC<{ hoaId: number }> = ({ hoaId }) => {
  const navigate = useNavigate();
  const [homes, setHomes] = useState<HomeSchema[]>([]);
  const [filteredHomes, setFilteredHomes] = useState<HomeSchema[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [errorTitle, setErrorTitle] = useState<string | null>(null);
  const [showCreateHomeError, setShowCreateHomeError] = useState(false);
  const [fetchErrorTitle, setFetchErrorTitle] = useState<string>('');
  const [fetchError, setFetchError] = useState<string>('');
  const [showFetchError, setShowFetchError] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newHome, setNewHome] =
    useState<HomeCreateOrUpdateSchema>(initialHomeState);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const [currentOwner, setCurrentOwner] = useState({
    first_name: '',
    last_name: '',
  });

  useEffect(() => {
    const fetchHomes = async () => {
      try {
        setLoading(true);
        const response = await homeService.getHomesByHoa({ hoaId });
        setHomes(response.data);
        setFilteredHomes(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching homes:', err);
        let errorMessage = 'An unexpected error occurred while fetching homes.';
        if (err instanceof Error) {
          errorMessage = err.message;
        } else if (typeof err === 'string') {
          errorMessage = err;
        }

        setFetchErrorTitle('Failed to Load Homes');
        setFetchError(errorMessage);
        setShowFetchError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchHomes();
  }, [hoaId]);

  useEffect(() => {
    const filtered = homes.filter((home) => {
      const fullAddress = getFullAddress(home).toLowerCase();
      // const ownerNames = home.owners
      //   .map((owner) => `${owner.first_name} ${owner.last_name}`.toLowerCase())
      //   .join(' ');
      const searchTermLower = searchTerm.toLowerCase();

      return fullAddress.includes(searchTermLower);
      // ||
      // ownerNames.includes(searchTermLower)
    });
    setFilteredHomes(filtered);
  }, [searchTerm, homes]);

  const getFullAddress = (home: HomeSchema): string => {
    const parts = [
      home.street,
      home.street2,
      home.city,
      home.state,
      home.zip,
      home.country,
    ].filter(Boolean);
    return parts.join(', ');
  };

  const handleRowClick = (home: HomeSchema) => {
    navigate(`/hoa/${hoaId}/home/${home.id}`);
  };

  const handleAddHome = () => {
    setIsDialogOpen(true);
  };

  const resetForm = () => {
    setNewHome({ ...initialHomeState, hoa_id: hoaId });
    setFormErrors({});
    setTouchedFields({});
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    resetForm();
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const validateField = (
    name: keyof HomeCreateOrUpdateSchema,
    value: HomeCreateOrUpdateSchema[keyof HomeCreateOrUpdateSchema]
  ): string | undefined => {
    let error: string | undefined;
    switch (name) {
      case 'street':
      case 'city':
      case 'state':
      case 'zip':
        error = !value
          ? `${name.charAt(0).toUpperCase() + name.slice(1)} is required`
          : undefined;
        break;
      case 'property_type':
      case 'status':
        if (!value || value === 'Select Type' || value === 'Select Status') {
          error = `${name.charAt(0).toUpperCase() + name.slice(1).replace('_', ' ')} is required`;
        }
        break;
    }
    return error;
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setTouchedFields((prev) => ({ ...prev, [name]: true }));

    const error = validateField(name as keyof HomeCreateOrUpdateSchema, value);
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (error) {
        newErrors[name] = error;
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  const validateForm = (): boolean => {
    const errors: FormErrors = {};
    (Object.keys(newHome) as Array<keyof HomeCreateOrUpdateSchema>).forEach(
      (key) => {
        const error = validateField(key, newHome[key]);
        if (error && key !== 'hoa_id') {
          errors[key] = error;
        }
      }
    );
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | { target: { name: string; value: string } }
  ) => {
    const { name, value } = e.target;
    setNewHome((prev) => ({ ...prev, [name]: value }));
    setTouchedFields((prev) => ({ ...prev, [name]: true }));

    const error = validateField(name as keyof HomeCreateOrUpdateSchema, value);
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (error) {
        newErrors[name] = error;
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  const handleOwnerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCurrentOwner((prev) => ({ ...prev, [name]: value }));
  };

  // const addOwner = () => {
  //   if (currentOwner.first_name && currentOwner.last_name) {
  //     setNewHome((prev) => {
  //       const updatedOwners = [...prev.owners, currentOwner];
  //       const error = validateField('owners', updatedOwners);
  //       setFormErrors((prevErrors) => {
  //         if (error) {
  //           return { ...prevErrors, owners: error };
  //         } else {
  //           const { owners, ...rest } = prevErrors;
  //           return rest;
  //         }
  //       });
  //       return { ...prev, owners: updatedOwners };
  //     });
  //     setCurrentOwner({ first_name: '', last_name: '' });
  //   }
  // };

  // const removeOwner = (index: number) => {
  //   setNewHome((prev) => {
  //     const updatedOwners = prev.owners.filter((_, i) => i !== index);
  //     const error = validateField('owners', updatedOwners);
  //     setFormErrors((prevErrors) => {
  //       if (error) {
  //         return { ...prevErrors, owners: error };
  //       } else {
  //         const { owners, ...rest } = prevErrors;
  //         return rest;
  //       }
  //     });
  //     return { ...prev, owners: updatedOwners };
  //   });
  // };

  const handleSaveHome = async () => {
    if (validateForm()) {
      try {
        const response = await homeService.createHome({
          homeCreateOrUpdateSchema: { ...newHome, hoa_id: hoaId },
        });
        setHomes((prevHomes) => [...prevHomes, response.data]);
        handleDialogClose();
      } catch (err) {
        console.error('Error creating home:', err);
        let errorMessage = 'An unexpected error occurred. Please try again.';
        if (err instanceof Error) {
          errorMessage = err.message;
        } else if (typeof err === 'string') {
          errorMessage = err;
        }

        setErrorTitle('Failed to create home. Please review your data.');
        setError(errorMessage);
        setShowCreateHomeError(true);
      }
    }
  };

  const isFormValid = () => {
    // Check for any existing errors
    if (Object.keys(formErrors).length > 0) {
      return false;
    }

    // Required fields
    const requiredFields = [
      'owners',
      'street',
      'city',
      'state',
      'zip',
      'property_type',
      'status',
    ];

    return requiredFields.every((field) => {
      const value = newHome[field as keyof HomeCreateOrUpdateSchema];
      // if (field === 'owners') {
      //   return newHome.owners !== undefined && newHome.owners.length > 0;
      // }
      return value !== undefined && value !== '';
    });
  };

  if (loading) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return (
    <>
      <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0 mb-4">
        <Heading level={4}>Home List</Heading>
        <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-4">
          <Button color="sky" onClick={handleAddHome}>
            <PlusIcon className="h-5 w-5 mr-2" />
            Add Home
          </Button>
          <div className="w-full sm:w-64 bg-stone-300 dark:bg-gray-800">
            <InputGroup className="[&>[data-slot=icon]]:text-zinc-500 dark:[&>[data-slot=icon]]:text-white">
              <MagnifyingGlassIcon data-slot="icon" />
              <Input
                type="search"
                placeholder="Search by address or name..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </InputGroup>
          </div>
        </div>
      </div>

      <ShowAlert
        alertType="error"
        alertTitle={fetchErrorTitle}
        alertMessage={fetchError}
        isVisible={showFetchError}
        onClose={() => setShowFetchError(false)}
        timeout={5000}
        isInline={true}
      />

      <div className="bg-slate-100 dark:bg-gray-600 rounded-lg shadow transition-colors duration-200 overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <div className="max-h-[500px] overflow-y-auto">
                  <Table>
                    <TableHead className="sticky top-0 z-10 bg-stone-100 dark:bg-gray-600">
                      <TableRow>
                        <TableHeader className="text-base font-semibold">
                          Address
                        </TableHeader>
                        <TableHeader className="text-base font-semibold">
                          Property Type
                        </TableHeader>
                        <TableHeader className="text-base font-semibold">
                          Status
                        </TableHeader>
                        {/* <TableHeader className="text-base font-semibold">
                          Owners
                        </TableHeader> */}
                        <TableHeader className="text-base font-semibold">
                          Contact
                        </TableHeader>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredHomes.map((home) => (
                        <TableRow
                          key={home.id}
                          onClick={() => handleRowClick(home)}
                          className="cursor-pointer hover:bg-stone-200 dark:hover:bg-zinc-700 transition-colors duration-150"
                        >
                          <TableCell>{getFullAddress(home)}</TableCell>
                          <TableCell>{home.property_type}</TableCell>
                          <TableCell>{home.status}</TableCell>
                          {/* <TableCell>
                            {home.owners.map((owner, index) => (
                              <div key={index}>
                                {owner.first_name} {owner.last_name}
                              </div>
                            ))}
                          </TableCell> */}
                          {/* <TableCell>
                            {home.email && <div>{home.email}</div>}
                            {home.phone && (
                              <div>{formatPhoneNumber(home.phone)}</div>
                            )}
                          </TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        className="w-full max-w-2xl mx-auto"
      >
        <div className="relative flex flex-col h-full">
          <div className="p-4 md:p-6">
            <DialogTitle className="text-xl font-semibold">
              New Home
            </DialogTitle>
            <button
              onClick={handleDialogClose}
              className="absolute right-4 top-4 p-2 hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-full transition-colors duration-150"
              aria-label="Close dialog"
            >
              <XMarkIcon className="h-5 w-5 text-zinc-600 dark:text-zinc-100" />
            </button>

            <Divider className="my-4" />

            <div className="mb-4">
              <ShowAlert
                alertType="error"
                alertTitle={errorTitle ?? 'Something went wrong'}
                alertMessage={error ?? 'Please try again'}
                isVisible={showCreateHomeError}
                onClose={() => setShowCreateHomeError(false)}
                timeout={20000}
                isInline={true}
              />
            </div>

            <DialogBody>
              <Fieldset>
                <FieldGroup>
                  {/* Owners Section */}
                  {/* <Field>
                    <Label>Owners*</Label>
                    <Description>at least one is required</Description>
                    <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                      <div className="col-span-1 md:col-span-2">
                        <Input
                          name="first_name"
                          value={currentOwner.first_name}
                          onChange={handleOwnerChange}
                          placeholder="First Name"
                          className="w-full"
                        />
                      </div>
                      <div className="col-span-1 md:col-span-2">
                        <Input
                          name="last_name"
                          value={currentOwner.last_name}
                          onChange={handleOwnerChange}
                          placeholder="Last Name"
                          className="w-full"
                        />
                      </div>
                      <div className="col-span-1">
                        <Button
                          outline
                          onClick={addOwner}
                          type="button"
                          className="w-full"
                        >
                          Add
                        </Button>
                      </div>
                    </div>
                    {newHome.owners.map((owner, index) => (
                      <div
                        key={index}
                        className="flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0 sm:space-x-2 mb-2 mt-2"
                      >
                        <Label className="sm:mr-4">
                          {owner.first_name} {owner.last_name}
                        </Label>
                        <Button
                          onClick={() => removeOwner(index)}
                          type="button"
                          color="rose"
                          className="w-full sm:w-auto"
                        >
                          Remove
                        </Button>
                      </div>
                    ))}
                    {touchedFields.owners && formErrors.owners && (
                      <ErrorMessage>{formErrors.owners}</ErrorMessage>
                    )}
                  </Field> */}

                  {/* Address Fields */}
                  <div className="space-y-4">
                    <Field>
                      <Label htmlFor="street">Street*</Label>
                      <Input
                        id="street"
                        name="street"
                        value={newHome.street}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter street address"
                        required
                        className="w-full"
                        data-invalid={touchedFields.street && formErrors.street}
                      />
                      {touchedFields.street && formErrors.street && (
                        <ErrorMessage>{formErrors.street}</ErrorMessage>
                      )}
                    </Field>

                    <Field>
                      <Label htmlFor="street2">Street 2</Label>
                      <Input
                        id="street2"
                        name="street2"
                        value={newHome.street2 || ''}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter additional street information (optional)"
                        className="w-full"
                      />
                    </Field>
                  </div>

                  {/* City, State */}
                  <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                    <Field className="col-span-1 md:col-span-3">
                      <Label htmlFor="city">City*</Label>
                      <Input
                        id="city"
                        name="city"
                        value={newHome.city}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter city"
                        required
                        className="w-full"
                        data-invalid={touchedFields.city && formErrors.city}
                      />
                      {touchedFields.city && formErrors.city && (
                        <ErrorMessage>{formErrors.city}</ErrorMessage>
                      )}
                    </Field>

                    <Field className="col-span-1 md:col-span-2">
                      <Label htmlFor="state">State*</Label>
                      <div className="mt-3">
                        <Dropdown>
                          <div className="w-full">
                            <DropdownButton
                              outline
                              className="w-full px-3 py-2 flex justify-between items-center text-left border border-gray-300 rounded-lg"
                            >
                              {newHome?.state || 'State'}
                              <ChevronDownIcon className="h-5 w-5 ml-2" />
                            </DropdownButton>
                          </div>
                          <DropdownMenu className="w-full max-h-60 overflow-y-auto">
                            {usStates.map((state) => (
                              <DropdownItem
                                key={state.label}
                                onClick={() =>
                                  handleInputChange({
                                    target: {
                                      name: 'state',
                                      value: state.value,
                                      type: 'change',
                                      checked: false,
                                      nodeName: 'INPUT',
                                    } as unknown as EventTarget &
                                      HTMLInputElement,
                                  } as React.ChangeEvent<HTMLInputElement>)
                                }
                              >
                                {state.label}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                      {touchedFields.state && formErrors.state && (
                        <ErrorMessage>{formErrors.state}</ErrorMessage>
                      )}
                    </Field>
                  </div>

                  {/* Contact Information */}
                  <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                    <Field className="col-span-1 md:col-span-2">
                      <Label htmlFor="zip">ZIP Code*</Label>
                      <Input
                        id="zip"
                        name="zip"
                        value={newHome.zip}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter ZIP code"
                        required
                        className="w-full"
                        data-invalid={touchedFields.zip && formErrors.zip}
                      />
                      {touchedFields.zip && formErrors.zip && (
                        <ErrorMessage>{formErrors.zip}</ErrorMessage>
                      )}
                    </Field>

                    {/* <Field className="col-span-1 md:col-span-3">
                      <Label htmlFor="phone">Contact Phone</Label>
                      <Input
                        id="phone"
                        name="phone"
                        type="tel"
                        value={newHome.phone || ''}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter contact phone"
                        className="w-full"
                        data-invalid={touchedFields.phone && formErrors.phone}
                      />
                      {touchedFields.phone && formErrors.phone && (
                        <ErrorMessage>{formErrors.phone}</ErrorMessage>
                      )}
                    </Field> */}
                  </div>

                  {/* <Field className="col-span-1 md:col-span-3">
                    <Label htmlFor="email">Contact Email</Label>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      value={newHome.email || ''}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Enter contact email"
                      className="w-full"
                      data-invalid={touchedFields.email && formErrors.email}
                    />
                    {touchedFields.email && formErrors.email && (
                      <ErrorMessage>{formErrors.email}</ErrorMessage>
                    )}
                  </Field> */}

                  {/* Property Type and Status */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <Field>
                      <Label htmlFor="property_type">Property Type*</Label>
                      <Dropdown>
                        <div className="w-full">
                          <DropdownButton
                            outline
                            className="w-full px-3 py-2 flex justify-between items-center text-left border border-gray-300 rounded-lg"
                          >
                            {newHome.property_type || 'Select Property Type'}
                            <ChevronDownIcon className="h-5 w-5 ml-2" />
                          </DropdownButton>
                        </div>
                        <DropdownMenu className="w-full">
                          {[
                            'Single Family',
                            'Townhouse',
                            'Condo',
                            'Apartment',
                            'Other',
                          ].map((type) => (
                            <DropdownItem
                              key={type}
                              onClick={() =>
                                handleInputChange({
                                  target: {
                                    name: 'property_type',
                                    value: type,
                                    type: 'change',
                                    checked: false,
                                    nodeName: 'INPUT',
                                  } as unknown as EventTarget &
                                    HTMLInputElement,
                                } as React.ChangeEvent<HTMLInputElement>)
                              }
                            >
                              {type}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      {touchedFields.property_type &&
                        formErrors.property_type && (
                          <ErrorMessage>
                            {formErrors.property_type}
                          </ErrorMessage>
                        )}
                    </Field>

                    <Field>
                      <Label htmlFor="status">Status*</Label>
                      <Dropdown>
                        <div className="w-full">
                          <DropdownButton
                            outline
                            className="w-full px-3 py-2 flex justify-between items-center text-left border border-gray-300 rounded-lg"
                          >
                            {newHome.status || 'Select Status'}
                            <ChevronDownIcon className="h-5 w-5 ml-2" />
                          </DropdownButton>
                        </div>
                        <DropdownMenu className="w-full">
                          {[
                            'Owner Occupied',
                            'Renter Occupied',
                            'Vacant',
                            'For Sale',
                            'For Rent',
                            'Unknown',
                          ].map((status) => (
                            <DropdownItem
                              key={status}
                              onClick={() =>
                                handleInputChange({
                                  target: {
                                    name: 'status',
                                    value: status,
                                    type: 'change',
                                    checked: false,
                                    nodeName: 'INPUT',
                                  } as unknown as EventTarget &
                                    HTMLInputElement,
                                } as React.ChangeEvent<HTMLInputElement>)
                              }
                            >
                              {status}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      {touchedFields.status && formErrors.status && (
                        <ErrorMessage>{formErrors.status}</ErrorMessage>
                      )}
                    </Field>
                  </div>
                </FieldGroup>
              </Fieldset>
            </DialogBody>
          </div>

          <div className="sticky bottom-0 mt-auto border-t border-gray-200 dark:border-gray-700 bg-stone-100 dark:bg-gray-800 p-4 md:p-6">
            <div className="flex flex-col space-y-3">
              <Button
                onClick={handleSaveHome}
                color="sky"
                disabled={!isFormValid()}
                className="w-full disabled:opacity-50"
              >
                Save
              </Button>
              <Button
                onClick={handleDialogClose}
                color="zinc"
                className="w-full"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
export default HomeList;
