import React, { useState } from 'react';
import { Heading } from './Components/Catalyst/heading';
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from './Components/Catalyst/dropdown';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

interface Stat {
  name: string;
  stat: string;
}

const stats: Stat[] = [
  { name: 'Homes Under Management', stat: '129' },
  { name: 'Open Violations', stat: '5' },
  { name: 'Fines Paid', stat: '$15,123' },
];

const dateRanges = [
  { label: 'Last 7 days', value: '7d' },
  { label: 'Last 30 days', value: '30d' },
  { label: 'Last 90 days', value: '90d' },
  { label: 'Year to date', value: 'ytd' },
];

const DashboardMetrics: React.FC = () => {
  const [selectedRange, setSelectedRange] = useState(dateRanges[1]);

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <Heading
          level={4}
          className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
        >
          Key Metrics
        </Heading>
        <Dropdown>
          <DropdownButton
            color="sky"
            className="flex items-center gap-x-1 text-sm font-medium leading-6"
          >
            {selectedRange.label}
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </DropdownButton>
          <DropdownMenu className="!bg-stone-100 dark:!bg-gray-900 !backdrop-blur-none">
            {dateRanges.map((range) => (
              <DropdownItem
                key={range.value}
                onClick={() => setSelectedRange(range)}
                className="data-[focus]:bg-sky-600/70 data-[focus]:text-white"
              >
                {range.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>

      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.name}
            className="overflow-hidden rounded-lg bg-stone-100 dark:bg-gray-600 px-4 py-5 shadow sm:p-6 transition-colors duration-200"
          >
            <dt className="truncate text-sm font-medium text-gray-900 dark:text-white">
              {item.name}
            </dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900 dark:text-white">
              {item.stat}
            </dd>
          </div>
        ))}
      </dl>
    </>
  );
};

export default DashboardMetrics;
