/* tslint:disable */
/* eslint-disable */
/**
 * Violation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}
/**
 * Schema for creating a new violation
 * @export
 * @interface ViolationCreate
 */
export interface ViolationCreate {
    /**
     * ID of the home associated with the violation
     * @type {number}
     * @memberof ViolationCreate
     */
    'home_id': number;
    /**
     * Category of the violation
     * @type {string}
     * @memberof ViolationCreate
     */
    'violation_type': string;
    /**
     * Severity level of the violation
     * @type {string}
     * @memberof ViolationCreate
     */
    'severity': string;
    /**
     * When the violation occurred
     * @type {string}
     * @memberof ViolationCreate
     */
    'date_of_violation'?: string;
    /**
     * Brief description of the violation
     * @type {string}
     * @memberof ViolationCreate
     */
    'description': string;
    /**
     * Detailed message about the violation
     * @type {string}
     * @memberof ViolationCreate
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ViolationCreate
     */
    'due_date'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ViolationCreate
     */
    'fine_amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationCreate
     */
    'image_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationCreate
     */
    'document_url'?: string | null;
    /**
     * Status of the violation
     * @type {string}
     * @memberof ViolationCreate
     */
    'status'?: string;
}
/**
 * Schema for returning a list of violations
 * @export
 * @interface ViolationList
 */
export interface ViolationList {
    /**
     * 
     * @type {number}
     * @memberof ViolationList
     */
    'total': number;
    /**
     * 
     * @type {Array<ViolationRead>}
     * @memberof ViolationList
     */
    'violations': Array<ViolationRead>;
}
/**
 * Schema for returning violation data
 * @export
 * @interface ViolationRead
 */
export interface ViolationRead {
    /**
     * ID of the home associated with the violation
     * @type {number}
     * @memberof ViolationRead
     */
    'home_id': number;
    /**
     * Category of the violation
     * @type {string}
     * @memberof ViolationRead
     */
    'violation_type': string;
    /**
     * Severity level of the violation
     * @type {string}
     * @memberof ViolationRead
     */
    'severity': string;
    /**
     * When the violation occurred
     * @type {string}
     * @memberof ViolationRead
     */
    'date_of_violation'?: string;
    /**
     * Brief description of the violation
     * @type {string}
     * @memberof ViolationRead
     */
    'description': string;
    /**
     * Detailed message about the violation
     * @type {string}
     * @memberof ViolationRead
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ViolationRead
     */
    'due_date'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ViolationRead
     */
    'fine_amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationRead
     */
    'image_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationRead
     */
    'document_url'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ViolationRead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ViolationRead
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ViolationRead
     */
    'resolution_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationRead
     */
    'follow_up_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationRead
     */
    'fine_paid_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationRead
     */
    'notification_sent_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationRead
     */
    'response_received_date'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ViolationRead
     */
    'created_by': number;
    /**
     * 
     * @type {number}
     * @memberof ViolationRead
     */
    'updated_by'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationRead
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof ViolationRead
     */
    'date_updated'?: string | null;
}
/**
 * Schema for updating an existing violation
 * @export
 * @interface ViolationUpdate
 */
export interface ViolationUpdate {
    /**
     * 
     * @type {string}
     * @memberof ViolationUpdate
     */
    'violation_type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationUpdate
     */
    'severity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationUpdate
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationUpdate
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationUpdate
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationUpdate
     */
    'due_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationUpdate
     */
    'resolution_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationUpdate
     */
    'follow_up_date'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ViolationUpdate
     */
    'fine_amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationUpdate
     */
    'fine_paid_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationUpdate
     */
    'image_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationUpdate
     */
    'document_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationUpdate
     */
    'notification_sent_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationUpdate
     */
    'response_received_date'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ViolationUpdate
     */
    'updated_by'?: number | null;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootLivenessGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/liveness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootReadinessGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/readiness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.rootGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootLivenessGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootLivenessGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.rootLivenessGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootReadinessGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootReadinessGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.rootReadinessGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.rootGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootLivenessGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.rootLivenessGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootReadinessGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.rootReadinessGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rootGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rootGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rootLivenessGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rootLivenessGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rootReadinessGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rootReadinessGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ViolationsApi - axios parameter creator
 * @export
 */
export const ViolationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new violation record for a specific HOA
         * @summary Create Violation
         * @param {number} hoaId 
         * @param {ViolationCreate} violationCreate 
         * @param {number} [currentUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createViolation: async (hoaId: number, violationCreate: ViolationCreate, currentUserId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('createViolation', 'hoaId', hoaId)
            // verify required parameter 'violationCreate' is not null or undefined
            assertParamExists('createViolation', 'violationCreate', violationCreate)
            const localVarPath = `/violations/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentUserId !== undefined) {
                localVarQueryParameter['current_user_id'] = currentUserId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(violationCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new violation record for a specific HOA
         * @summary Create Violation
         * @param {number} hoaId 
         * @param {ViolationCreate} violationCreate 
         * @param {number} [currentUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createViolation_1: async (hoaId: number, violationCreate: ViolationCreate, currentUserId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('createViolation_1', 'hoaId', hoaId)
            // verify required parameter 'violationCreate' is not null or undefined
            assertParamExists('createViolation_1', 'violationCreate', violationCreate)
            const localVarPath = `/violations/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentUserId !== undefined) {
                localVarQueryParameter['current_user_id'] = currentUserId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(violationCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a violation within an HOA
         * @summary Delete Violation
         * @param {number} hoaId 
         * @param {number} violationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteViolation: async (hoaId: number, violationId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('deleteViolation', 'hoaId', hoaId)
            // verify required parameter 'violationId' is not null or undefined
            assertParamExists('deleteViolation', 'violationId', violationId)
            const localVarPath = `/violations/{hoa_id}/{violation_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)))
                .replace(`{${"violation_id"}}`, encodeURIComponent(String(violationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a violation within an HOA
         * @summary Delete Violation
         * @param {number} hoaId 
         * @param {number} violationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteViolation_2: async (hoaId: number, violationId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('deleteViolation_2', 'hoaId', hoaId)
            // verify required parameter 'violationId' is not null or undefined
            assertParamExists('deleteViolation_2', 'violationId', violationId)
            const localVarPath = `/violations/{hoa_id}/{violation_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)))
                .replace(`{${"violation_id"}}`, encodeURIComponent(String(violationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all violations for a specific user
         * @summary Get All Violations For User
         * @param {number} [skip] Number of violations to skip
         * @param {number} [limit] Maximum number of violations to return
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllViolations: async (skip?: number, limit?: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/violations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all violations for a specific member
         * @summary Get All Violations For Member
         * @param {number} memberId 
         * @param {number} [skip] Number of violations to skip
         * @param {number} [limit] Maximum number of violations to return
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllViolationsForMember: async (memberId: number, skip?: number, limit?: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('getAllViolationsForMember', 'memberId', memberId)
            const localVarPath = `/violations/member/{member_id}`
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all violations for a specific member
         * @summary Get All Violations For Member
         * @param {number} memberId 
         * @param {number} [skip] Number of violations to skip
         * @param {number} [limit] Maximum number of violations to return
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllViolationsForMember_3: async (memberId: number, skip?: number, limit?: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('getAllViolationsForMember_3', 'memberId', memberId)
            const localVarPath = `/violations/member/{member_id}`
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all violations for a specific user
         * @summary Get All Violations For User
         * @param {number} [skip] Number of violations to skip
         * @param {number} [limit] Maximum number of violations to return
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllViolations_4: async (skip?: number, limit?: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/violations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a specific violation by ID
         * @summary Get Violation By Id
         * @param {number} violationId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViolationById: async (violationId: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'violationId' is not null or undefined
            assertParamExists('getViolationById', 'violationId', violationId)
            const localVarPath = `/violations/{violation_id}`
                .replace(`{${"violation_id"}}`, encodeURIComponent(String(violationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a specific violation by ID
         * @summary Get Violation By Id
         * @param {number} violationId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViolationById_5: async (violationId: number, tokenPayload?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'violationId' is not null or undefined
            assertParamExists('getViolationById_5', 'violationId', violationId)
            const localVarPath = `/violations/{violation_id}`
                .replace(`{${"violation_id"}}`, encodeURIComponent(String(violationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tokenPayload != null) {
                localVarHeaderParameter['token_payload'] = String(tokenPayload);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all violations for a specific HOA
         * @summary Get Violations By Hoa
         * @param {number} hoaId 
         * @param {number} [skip] Number of violations to skip
         * @param {number} [limit] Maximum number of violations to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViolationsByHoa: async (hoaId: number, skip?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('getViolationsByHoa', 'hoaId', hoaId)
            const localVarPath = `/violations/hoa/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all violations for a specific HOA
         * @summary Get Violations By Hoa
         * @param {number} hoaId 
         * @param {number} [skip] Number of violations to skip
         * @param {number} [limit] Maximum number of violations to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViolationsByHoa_6: async (hoaId: number, skip?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('getViolationsByHoa_6', 'hoaId', hoaId)
            const localVarPath = `/violations/hoa/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all violations for a specific home within an HOA
         * @summary Get Violations By Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViolationsByHome: async (hoaId: number, homeId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('getViolationsByHome', 'hoaId', hoaId)
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('getViolationsByHome', 'homeId', homeId)
            const localVarPath = `/violations/home/{hoa_id}/{home_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)))
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all violations for a specific home within an HOA
         * @summary Get Violations By Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViolationsByHome_7: async (hoaId: number, homeId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('getViolationsByHome_7', 'hoaId', hoaId)
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('getViolationsByHome_7', 'homeId', homeId)
            const localVarPath = `/violations/home/{hoa_id}/{home_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)))
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing violation within an HOA
         * @summary Update Violation
         * @param {number} violationId 
         * @param {ViolationUpdate} violationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateViolation: async (violationId: number, violationUpdate: ViolationUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'violationId' is not null or undefined
            assertParamExists('updateViolation', 'violationId', violationId)
            // verify required parameter 'violationUpdate' is not null or undefined
            assertParamExists('updateViolation', 'violationUpdate', violationUpdate)
            const localVarPath = `/violations/{violation_id}`
                .replace(`{${"violation_id"}}`, encodeURIComponent(String(violationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(violationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing violation within an HOA
         * @summary Update Violation
         * @param {number} violationId 
         * @param {ViolationUpdate} violationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateViolation_8: async (violationId: number, violationUpdate: ViolationUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'violationId' is not null or undefined
            assertParamExists('updateViolation_8', 'violationId', violationId)
            // verify required parameter 'violationUpdate' is not null or undefined
            assertParamExists('updateViolation_8', 'violationUpdate', violationUpdate)
            const localVarPath = `/violations/{violation_id}`
                .replace(`{${"violation_id"}}`, encodeURIComponent(String(violationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(violationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ViolationsApi - functional programming interface
 * @export
 */
export const ViolationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ViolationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new violation record for a specific HOA
         * @summary Create Violation
         * @param {number} hoaId 
         * @param {ViolationCreate} violationCreate 
         * @param {number} [currentUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createViolation(hoaId: number, violationCreate: ViolationCreate, currentUserId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createViolation(hoaId, violationCreate, currentUserId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ViolationsApi.createViolation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new violation record for a specific HOA
         * @summary Create Violation
         * @param {number} hoaId 
         * @param {ViolationCreate} violationCreate 
         * @param {number} [currentUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createViolation_1(hoaId: number, violationCreate: ViolationCreate, currentUserId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createViolation_1(hoaId, violationCreate, currentUserId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ViolationsApi.createViolation_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a violation within an HOA
         * @summary Delete Violation
         * @param {number} hoaId 
         * @param {number} violationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteViolation(hoaId: number, violationId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteViolation(hoaId, violationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ViolationsApi.deleteViolation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a violation within an HOA
         * @summary Delete Violation
         * @param {number} hoaId 
         * @param {number} violationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteViolation_2(hoaId: number, violationId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteViolation_2(hoaId, violationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ViolationsApi.deleteViolation_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all violations for a specific user
         * @summary Get All Violations For User
         * @param {number} [skip] Number of violations to skip
         * @param {number} [limit] Maximum number of violations to return
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllViolations(skip?: number, limit?: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllViolations(skip, limit, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ViolationsApi.getAllViolations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all violations for a specific member
         * @summary Get All Violations For Member
         * @param {number} memberId 
         * @param {number} [skip] Number of violations to skip
         * @param {number} [limit] Maximum number of violations to return
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllViolationsForMember(memberId: number, skip?: number, limit?: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllViolationsForMember(memberId, skip, limit, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ViolationsApi.getAllViolationsForMember']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all violations for a specific member
         * @summary Get All Violations For Member
         * @param {number} memberId 
         * @param {number} [skip] Number of violations to skip
         * @param {number} [limit] Maximum number of violations to return
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllViolationsForMember_3(memberId: number, skip?: number, limit?: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllViolationsForMember_3(memberId, skip, limit, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ViolationsApi.getAllViolationsForMember_3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all violations for a specific user
         * @summary Get All Violations For User
         * @param {number} [skip] Number of violations to skip
         * @param {number} [limit] Maximum number of violations to return
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllViolations_4(skip?: number, limit?: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllViolations_4(skip, limit, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ViolationsApi.getAllViolations_4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a specific violation by ID
         * @summary Get Violation By Id
         * @param {number} violationId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getViolationById(violationId: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getViolationById(violationId, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ViolationsApi.getViolationById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a specific violation by ID
         * @summary Get Violation By Id
         * @param {number} violationId 
         * @param {string | null} [tokenPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getViolationById_5(violationId: number, tokenPayload?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getViolationById_5(violationId, tokenPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ViolationsApi.getViolationById_5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all violations for a specific HOA
         * @summary Get Violations By Hoa
         * @param {number} hoaId 
         * @param {number} [skip] Number of violations to skip
         * @param {number} [limit] Maximum number of violations to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getViolationsByHoa(hoaId: number, skip?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getViolationsByHoa(hoaId, skip, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ViolationsApi.getViolationsByHoa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all violations for a specific HOA
         * @summary Get Violations By Hoa
         * @param {number} hoaId 
         * @param {number} [skip] Number of violations to skip
         * @param {number} [limit] Maximum number of violations to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getViolationsByHoa_6(hoaId: number, skip?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getViolationsByHoa_6(hoaId, skip, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ViolationsApi.getViolationsByHoa_6']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all violations for a specific home within an HOA
         * @summary Get Violations By Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getViolationsByHome(hoaId: number, homeId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ViolationRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getViolationsByHome(hoaId, homeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ViolationsApi.getViolationsByHome']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all violations for a specific home within an HOA
         * @summary Get Violations By Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getViolationsByHome_7(hoaId: number, homeId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ViolationRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getViolationsByHome_7(hoaId, homeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ViolationsApi.getViolationsByHome_7']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing violation within an HOA
         * @summary Update Violation
         * @param {number} violationId 
         * @param {ViolationUpdate} violationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateViolation(violationId: number, violationUpdate: ViolationUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateViolation(violationId, violationUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ViolationsApi.updateViolation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing violation within an HOA
         * @summary Update Violation
         * @param {number} violationId 
         * @param {ViolationUpdate} violationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateViolation_8(violationId: number, violationUpdate: ViolationUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateViolation_8(violationId, violationUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ViolationsApi.updateViolation_8']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ViolationsApi - factory interface
 * @export
 */
export const ViolationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ViolationsApiFp(configuration)
    return {
        /**
         * Create a new violation record for a specific HOA
         * @summary Create Violation
         * @param {ViolationsApiCreateViolationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createViolation(requestParameters: ViolationsApiCreateViolationRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViolationRead> {
            return localVarFp.createViolation(requestParameters.hoaId, requestParameters.violationCreate, requestParameters.currentUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new violation record for a specific HOA
         * @summary Create Violation
         * @param {ViolationsApiCreateViolation0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createViolation_1(requestParameters: ViolationsApiCreateViolation0Request, options?: RawAxiosRequestConfig): AxiosPromise<ViolationRead> {
            return localVarFp.createViolation_1(requestParameters.hoaId, requestParameters.violationCreate, requestParameters.currentUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a violation within an HOA
         * @summary Delete Violation
         * @param {ViolationsApiDeleteViolationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteViolation(requestParameters: ViolationsApiDeleteViolationRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViolationRead> {
            return localVarFp.deleteViolation(requestParameters.hoaId, requestParameters.violationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a violation within an HOA
         * @summary Delete Violation
         * @param {ViolationsApiDeleteViolation0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteViolation_2(requestParameters: ViolationsApiDeleteViolation0Request, options?: RawAxiosRequestConfig): AxiosPromise<ViolationRead> {
            return localVarFp.deleteViolation_2(requestParameters.hoaId, requestParameters.violationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all violations for a specific user
         * @summary Get All Violations For User
         * @param {ViolationsApiGetAllViolationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllViolations(requestParameters: ViolationsApiGetAllViolationsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ViolationList> {
            return localVarFp.getAllViolations(requestParameters.skip, requestParameters.limit, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all violations for a specific member
         * @summary Get All Violations For Member
         * @param {ViolationsApiGetAllViolationsForMemberRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllViolationsForMember(requestParameters: ViolationsApiGetAllViolationsForMemberRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViolationList> {
            return localVarFp.getAllViolationsForMember(requestParameters.memberId, requestParameters.skip, requestParameters.limit, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all violations for a specific member
         * @summary Get All Violations For Member
         * @param {ViolationsApiGetAllViolationsForMember0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllViolationsForMember_3(requestParameters: ViolationsApiGetAllViolationsForMember0Request, options?: RawAxiosRequestConfig): AxiosPromise<ViolationList> {
            return localVarFp.getAllViolationsForMember_3(requestParameters.memberId, requestParameters.skip, requestParameters.limit, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all violations for a specific user
         * @summary Get All Violations For User
         * @param {ViolationsApiGetAllViolations0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllViolations_4(requestParameters: ViolationsApiGetAllViolations0Request = {}, options?: RawAxiosRequestConfig): AxiosPromise<ViolationList> {
            return localVarFp.getAllViolations_4(requestParameters.skip, requestParameters.limit, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a specific violation by ID
         * @summary Get Violation By Id
         * @param {ViolationsApiGetViolationByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViolationById(requestParameters: ViolationsApiGetViolationByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViolationRead> {
            return localVarFp.getViolationById(requestParameters.violationId, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a specific violation by ID
         * @summary Get Violation By Id
         * @param {ViolationsApiGetViolationById0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViolationById_5(requestParameters: ViolationsApiGetViolationById0Request, options?: RawAxiosRequestConfig): AxiosPromise<ViolationRead> {
            return localVarFp.getViolationById_5(requestParameters.violationId, requestParameters.tokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all violations for a specific HOA
         * @summary Get Violations By Hoa
         * @param {ViolationsApiGetViolationsByHoaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViolationsByHoa(requestParameters: ViolationsApiGetViolationsByHoaRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViolationList> {
            return localVarFp.getViolationsByHoa(requestParameters.hoaId, requestParameters.skip, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all violations for a specific HOA
         * @summary Get Violations By Hoa
         * @param {ViolationsApiGetViolationsByHoa0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViolationsByHoa_6(requestParameters: ViolationsApiGetViolationsByHoa0Request, options?: RawAxiosRequestConfig): AxiosPromise<ViolationList> {
            return localVarFp.getViolationsByHoa_6(requestParameters.hoaId, requestParameters.skip, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all violations for a specific home within an HOA
         * @summary Get Violations By Home
         * @param {ViolationsApiGetViolationsByHomeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViolationsByHome(requestParameters: ViolationsApiGetViolationsByHomeRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ViolationRead>> {
            return localVarFp.getViolationsByHome(requestParameters.hoaId, requestParameters.homeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all violations for a specific home within an HOA
         * @summary Get Violations By Home
         * @param {ViolationsApiGetViolationsByHome0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViolationsByHome_7(requestParameters: ViolationsApiGetViolationsByHome0Request, options?: RawAxiosRequestConfig): AxiosPromise<Array<ViolationRead>> {
            return localVarFp.getViolationsByHome_7(requestParameters.hoaId, requestParameters.homeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing violation within an HOA
         * @summary Update Violation
         * @param {ViolationsApiUpdateViolationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateViolation(requestParameters: ViolationsApiUpdateViolationRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViolationRead> {
            return localVarFp.updateViolation(requestParameters.violationId, requestParameters.violationUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing violation within an HOA
         * @summary Update Violation
         * @param {ViolationsApiUpdateViolation0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateViolation_8(requestParameters: ViolationsApiUpdateViolation0Request, options?: RawAxiosRequestConfig): AxiosPromise<ViolationRead> {
            return localVarFp.updateViolation_8(requestParameters.violationId, requestParameters.violationUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createViolation operation in ViolationsApi.
 * @export
 * @interface ViolationsApiCreateViolationRequest
 */
export interface ViolationsApiCreateViolationRequest {
    /**
     * 
     * @type {number}
     * @memberof ViolationsApiCreateViolation
     */
    readonly hoaId: number

    /**
     * 
     * @type {ViolationCreate}
     * @memberof ViolationsApiCreateViolation
     */
    readonly violationCreate: ViolationCreate

    /**
     * 
     * @type {number}
     * @memberof ViolationsApiCreateViolation
     */
    readonly currentUserId?: number
}

/**
 * Request parameters for createViolation_1 operation in ViolationsApi.
 * @export
 * @interface ViolationsApiCreateViolation0Request
 */
export interface ViolationsApiCreateViolation0Request {
    /**
     * 
     * @type {number}
     * @memberof ViolationsApiCreateViolation0
     */
    readonly hoaId: number

    /**
     * 
     * @type {ViolationCreate}
     * @memberof ViolationsApiCreateViolation0
     */
    readonly violationCreate: ViolationCreate

    /**
     * 
     * @type {number}
     * @memberof ViolationsApiCreateViolation0
     */
    readonly currentUserId?: number
}

/**
 * Request parameters for deleteViolation operation in ViolationsApi.
 * @export
 * @interface ViolationsApiDeleteViolationRequest
 */
export interface ViolationsApiDeleteViolationRequest {
    /**
     * 
     * @type {number}
     * @memberof ViolationsApiDeleteViolation
     */
    readonly hoaId: number

    /**
     * 
     * @type {number}
     * @memberof ViolationsApiDeleteViolation
     */
    readonly violationId: number
}

/**
 * Request parameters for deleteViolation_2 operation in ViolationsApi.
 * @export
 * @interface ViolationsApiDeleteViolation0Request
 */
export interface ViolationsApiDeleteViolation0Request {
    /**
     * 
     * @type {number}
     * @memberof ViolationsApiDeleteViolation0
     */
    readonly hoaId: number

    /**
     * 
     * @type {number}
     * @memberof ViolationsApiDeleteViolation0
     */
    readonly violationId: number
}

/**
 * Request parameters for getAllViolations operation in ViolationsApi.
 * @export
 * @interface ViolationsApiGetAllViolationsRequest
 */
export interface ViolationsApiGetAllViolationsRequest {
    /**
     * Number of violations to skip
     * @type {number}
     * @memberof ViolationsApiGetAllViolations
     */
    readonly skip?: number

    /**
     * Maximum number of violations to return
     * @type {number}
     * @memberof ViolationsApiGetAllViolations
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ViolationsApiGetAllViolations
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for getAllViolationsForMember operation in ViolationsApi.
 * @export
 * @interface ViolationsApiGetAllViolationsForMemberRequest
 */
export interface ViolationsApiGetAllViolationsForMemberRequest {
    /**
     * 
     * @type {number}
     * @memberof ViolationsApiGetAllViolationsForMember
     */
    readonly memberId: number

    /**
     * Number of violations to skip
     * @type {number}
     * @memberof ViolationsApiGetAllViolationsForMember
     */
    readonly skip?: number

    /**
     * Maximum number of violations to return
     * @type {number}
     * @memberof ViolationsApiGetAllViolationsForMember
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ViolationsApiGetAllViolationsForMember
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for getAllViolationsForMember_3 operation in ViolationsApi.
 * @export
 * @interface ViolationsApiGetAllViolationsForMember0Request
 */
export interface ViolationsApiGetAllViolationsForMember0Request {
    /**
     * 
     * @type {number}
     * @memberof ViolationsApiGetAllViolationsForMember0
     */
    readonly memberId: number

    /**
     * Number of violations to skip
     * @type {number}
     * @memberof ViolationsApiGetAllViolationsForMember0
     */
    readonly skip?: number

    /**
     * Maximum number of violations to return
     * @type {number}
     * @memberof ViolationsApiGetAllViolationsForMember0
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ViolationsApiGetAllViolationsForMember0
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for getAllViolations_4 operation in ViolationsApi.
 * @export
 * @interface ViolationsApiGetAllViolations0Request
 */
export interface ViolationsApiGetAllViolations0Request {
    /**
     * Number of violations to skip
     * @type {number}
     * @memberof ViolationsApiGetAllViolations0
     */
    readonly skip?: number

    /**
     * Maximum number of violations to return
     * @type {number}
     * @memberof ViolationsApiGetAllViolations0
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ViolationsApiGetAllViolations0
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for getViolationById operation in ViolationsApi.
 * @export
 * @interface ViolationsApiGetViolationByIdRequest
 */
export interface ViolationsApiGetViolationByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof ViolationsApiGetViolationById
     */
    readonly violationId: number

    /**
     * 
     * @type {string}
     * @memberof ViolationsApiGetViolationById
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for getViolationById_5 operation in ViolationsApi.
 * @export
 * @interface ViolationsApiGetViolationById0Request
 */
export interface ViolationsApiGetViolationById0Request {
    /**
     * 
     * @type {number}
     * @memberof ViolationsApiGetViolationById0
     */
    readonly violationId: number

    /**
     * 
     * @type {string}
     * @memberof ViolationsApiGetViolationById0
     */
    readonly tokenPayload?: string | null
}

/**
 * Request parameters for getViolationsByHoa operation in ViolationsApi.
 * @export
 * @interface ViolationsApiGetViolationsByHoaRequest
 */
export interface ViolationsApiGetViolationsByHoaRequest {
    /**
     * 
     * @type {number}
     * @memberof ViolationsApiGetViolationsByHoa
     */
    readonly hoaId: number

    /**
     * Number of violations to skip
     * @type {number}
     * @memberof ViolationsApiGetViolationsByHoa
     */
    readonly skip?: number

    /**
     * Maximum number of violations to return
     * @type {number}
     * @memberof ViolationsApiGetViolationsByHoa
     */
    readonly limit?: number
}

/**
 * Request parameters for getViolationsByHoa_6 operation in ViolationsApi.
 * @export
 * @interface ViolationsApiGetViolationsByHoa0Request
 */
export interface ViolationsApiGetViolationsByHoa0Request {
    /**
     * 
     * @type {number}
     * @memberof ViolationsApiGetViolationsByHoa0
     */
    readonly hoaId: number

    /**
     * Number of violations to skip
     * @type {number}
     * @memberof ViolationsApiGetViolationsByHoa0
     */
    readonly skip?: number

    /**
     * Maximum number of violations to return
     * @type {number}
     * @memberof ViolationsApiGetViolationsByHoa0
     */
    readonly limit?: number
}

/**
 * Request parameters for getViolationsByHome operation in ViolationsApi.
 * @export
 * @interface ViolationsApiGetViolationsByHomeRequest
 */
export interface ViolationsApiGetViolationsByHomeRequest {
    /**
     * 
     * @type {number}
     * @memberof ViolationsApiGetViolationsByHome
     */
    readonly hoaId: number

    /**
     * 
     * @type {number}
     * @memberof ViolationsApiGetViolationsByHome
     */
    readonly homeId: number
}

/**
 * Request parameters for getViolationsByHome_7 operation in ViolationsApi.
 * @export
 * @interface ViolationsApiGetViolationsByHome0Request
 */
export interface ViolationsApiGetViolationsByHome0Request {
    /**
     * 
     * @type {number}
     * @memberof ViolationsApiGetViolationsByHome0
     */
    readonly hoaId: number

    /**
     * 
     * @type {number}
     * @memberof ViolationsApiGetViolationsByHome0
     */
    readonly homeId: number
}

/**
 * Request parameters for updateViolation operation in ViolationsApi.
 * @export
 * @interface ViolationsApiUpdateViolationRequest
 */
export interface ViolationsApiUpdateViolationRequest {
    /**
     * 
     * @type {number}
     * @memberof ViolationsApiUpdateViolation
     */
    readonly violationId: number

    /**
     * 
     * @type {ViolationUpdate}
     * @memberof ViolationsApiUpdateViolation
     */
    readonly violationUpdate: ViolationUpdate
}

/**
 * Request parameters for updateViolation_8 operation in ViolationsApi.
 * @export
 * @interface ViolationsApiUpdateViolation0Request
 */
export interface ViolationsApiUpdateViolation0Request {
    /**
     * 
     * @type {number}
     * @memberof ViolationsApiUpdateViolation0
     */
    readonly violationId: number

    /**
     * 
     * @type {ViolationUpdate}
     * @memberof ViolationsApiUpdateViolation0
     */
    readonly violationUpdate: ViolationUpdate
}

/**
 * ViolationsApi - object-oriented interface
 * @export
 * @class ViolationsApi
 * @extends {BaseAPI}
 */
export class ViolationsApi extends BaseAPI {
    /**
     * Create a new violation record for a specific HOA
     * @summary Create Violation
     * @param {ViolationsApiCreateViolationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public createViolation(requestParameters: ViolationsApiCreateViolationRequest, options?: RawAxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).createViolation(requestParameters.hoaId, requestParameters.violationCreate, requestParameters.currentUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new violation record for a specific HOA
     * @summary Create Violation
     * @param {ViolationsApiCreateViolation0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public createViolation_1(requestParameters: ViolationsApiCreateViolation0Request, options?: RawAxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).createViolation_1(requestParameters.hoaId, requestParameters.violationCreate, requestParameters.currentUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a violation within an HOA
     * @summary Delete Violation
     * @param {ViolationsApiDeleteViolationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public deleteViolation(requestParameters: ViolationsApiDeleteViolationRequest, options?: RawAxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).deleteViolation(requestParameters.hoaId, requestParameters.violationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a violation within an HOA
     * @summary Delete Violation
     * @param {ViolationsApiDeleteViolation0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public deleteViolation_2(requestParameters: ViolationsApiDeleteViolation0Request, options?: RawAxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).deleteViolation_2(requestParameters.hoaId, requestParameters.violationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all violations for a specific user
     * @summary Get All Violations For User
     * @param {ViolationsApiGetAllViolationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public getAllViolations(requestParameters: ViolationsApiGetAllViolationsRequest = {}, options?: RawAxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).getAllViolations(requestParameters.skip, requestParameters.limit, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all violations for a specific member
     * @summary Get All Violations For Member
     * @param {ViolationsApiGetAllViolationsForMemberRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public getAllViolationsForMember(requestParameters: ViolationsApiGetAllViolationsForMemberRequest, options?: RawAxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).getAllViolationsForMember(requestParameters.memberId, requestParameters.skip, requestParameters.limit, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all violations for a specific member
     * @summary Get All Violations For Member
     * @param {ViolationsApiGetAllViolationsForMember0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public getAllViolationsForMember_3(requestParameters: ViolationsApiGetAllViolationsForMember0Request, options?: RawAxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).getAllViolationsForMember_3(requestParameters.memberId, requestParameters.skip, requestParameters.limit, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all violations for a specific user
     * @summary Get All Violations For User
     * @param {ViolationsApiGetAllViolations0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public getAllViolations_4(requestParameters: ViolationsApiGetAllViolations0Request = {}, options?: RawAxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).getAllViolations_4(requestParameters.skip, requestParameters.limit, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a specific violation by ID
     * @summary Get Violation By Id
     * @param {ViolationsApiGetViolationByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public getViolationById(requestParameters: ViolationsApiGetViolationByIdRequest, options?: RawAxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).getViolationById(requestParameters.violationId, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a specific violation by ID
     * @summary Get Violation By Id
     * @param {ViolationsApiGetViolationById0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public getViolationById_5(requestParameters: ViolationsApiGetViolationById0Request, options?: RawAxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).getViolationById_5(requestParameters.violationId, requestParameters.tokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all violations for a specific HOA
     * @summary Get Violations By Hoa
     * @param {ViolationsApiGetViolationsByHoaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public getViolationsByHoa(requestParameters: ViolationsApiGetViolationsByHoaRequest, options?: RawAxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).getViolationsByHoa(requestParameters.hoaId, requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all violations for a specific HOA
     * @summary Get Violations By Hoa
     * @param {ViolationsApiGetViolationsByHoa0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public getViolationsByHoa_6(requestParameters: ViolationsApiGetViolationsByHoa0Request, options?: RawAxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).getViolationsByHoa_6(requestParameters.hoaId, requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all violations for a specific home within an HOA
     * @summary Get Violations By Home
     * @param {ViolationsApiGetViolationsByHomeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public getViolationsByHome(requestParameters: ViolationsApiGetViolationsByHomeRequest, options?: RawAxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).getViolationsByHome(requestParameters.hoaId, requestParameters.homeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all violations for a specific home within an HOA
     * @summary Get Violations By Home
     * @param {ViolationsApiGetViolationsByHome0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public getViolationsByHome_7(requestParameters: ViolationsApiGetViolationsByHome0Request, options?: RawAxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).getViolationsByHome_7(requestParameters.hoaId, requestParameters.homeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing violation within an HOA
     * @summary Update Violation
     * @param {ViolationsApiUpdateViolationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public updateViolation(requestParameters: ViolationsApiUpdateViolationRequest, options?: RawAxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).updateViolation(requestParameters.violationId, requestParameters.violationUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing violation within an HOA
     * @summary Update Violation
     * @param {ViolationsApiUpdateViolation0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public updateViolation_8(requestParameters: ViolationsApiUpdateViolation0Request, options?: RawAxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).updateViolation_8(requestParameters.violationId, requestParameters.violationUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



