import { BodyRefreshToken, BodyVerifyToken } from './auth-api-client';
import { authService } from './ApiClients';
import { jwtDecode } from 'jwt-decode';
import { UserType } from './Constants/UserType';

interface DecodedToken {
  exp: number;
  sub?: string;
  email?: string;
  full_name?: string;
  user_type?: string;
  [key: string]: any;
}

export const getToken = (): string | null => {
  let token = localStorage.getItem('token');
  if (
    token === null ||
    token === undefined ||
    token === '' ||
    token === 'undefined'
  ) {
    token = sessionStorage.getItem('token');
  }
  if (
    token === null ||
    token === undefined ||
    token === '' ||
    token === 'undefined'
  ) {
    return null;
  }
  return token;
};

export const getRefreshToken = (): string | null => {
  let refreshToken =
    localStorage.getItem('refreshToken') ||
    sessionStorage.getItem('refreshToken');
  return refreshToken && refreshToken !== 'undefined' ? refreshToken : null;
};

export const getUserName = (): string | null => {
  let userName = localStorage.getItem('username');
  if (
    userName === null ||
    userName === undefined ||
    userName === '' ||
    userName === 'undefined'
  ) {
    userName = sessionStorage.getItem('username');
  }
  if (
    userName === null ||
    userName === undefined ||
    userName === '' ||
    userName === 'undefined'
  ) {
    return null;
  }
  return userName;
};

export const getRememberMe = (): string | null => {
  let rememberme = localStorage.getItem('rememberme');
  if (
    rememberme === null ||
    rememberme === undefined ||
    rememberme === '' ||
    rememberme === 'undefined' ||
    rememberme === 'false'
  ) {
    rememberme = sessionStorage.getItem('rememberme');
  }
  if (
    rememberme === null ||
    rememberme === undefined ||
    rememberme === '' ||
    rememberme === 'undefined' ||
    rememberme === 'false'
  ) {
    return null;
  }
  return rememberme;
};

export const clearToken = (): void => {
  localStorage.removeItem('token');
  sessionStorage.removeItem('token');
};

export const clearRefreshToken = (): void => {
  localStorage.removeItem('refreshToken');
  sessionStorage.removeItem('refreshToken');
};

export const clearUserName = (): void => {
  localStorage.removeItem('username');
  sessionStorage.removeItem('username');
};

export const clearRememberMe = (): void => {
  localStorage.removeItem('rememberme');
  sessionStorage.removeItem('rememberme');
};

export const clearSessionStorage = (): void => {
  sessionStorage.clear();
};

export const clearAllTokens = (): void => {
  clearToken();
  clearRefreshToken();
};

export const clearAll = (): void => {
  clearAllTokens();
  clearUserName();
  clearRememberMe();
  clearSessionStorage();
};

export const setToken = (token: string, isRememberMe: boolean): void => {
  if (isRememberMe) {
    localStorage.setItem('token', token);
  } else {
    sessionStorage.setItem('token', token);
  }
};

export const refreshAccessToken = async (
  refreshToken: string
): Promise<string | null> => {
  try {
    const bodyRefreshToken: BodyRefreshToken = {
      refresh_token: refreshToken,
    };
    const response = await authService.refreshToken({ bodyRefreshToken });
    const newToken = (response.data as { access_token: string }).access_token;
    return newToken;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    return null;
  }
};

export const verifyToken = async (token: string | null): Promise<boolean> => {
  try {
    if (token === null) {
      return false;
    }

    // Decode the token
    const decodedToken = jwtDecode<DecodedToken>(token);
    const currentTime = Math.floor(Date.now() / 1000);

    // Check if the token is expired
    if (decodedToken.exp < currentTime) {
      // Token is expired, try to refresh
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        const newToken = await refreshAccessToken(refreshToken);
        if (newToken) {
          // Update the token in storage
          setToken(newToken, !!getRememberMe());
          return true;
        }
      }
      return false;
    }

    // Verify the token with the server
    const bodyVerifyToken: BodyVerifyToken = { token };
    const response = await authService.verifyToken({ bodyVerifyToken });
    return (response.data as { isValid: boolean }).isValid;
  } catch (error) {
    console.error('Token verification failed:', error);
    return false;
  }
};

export const tokenIsUnexpired = async (
  token: string | null
): Promise<boolean> => {
  try {
    if (token === null) {
      return false;
    }

    // Decode the token
    const decodedToken = jwtDecode<DecodedToken>(token);
    const currentTime = Math.floor(Date.now() / 1000);

    // Check if the token is expired
    if (decodedToken.exp < currentTime) {
      // Token is expired, try to refresh
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        const newToken = await refreshAccessToken(refreshToken);
        if (newToken) {
          // Update the token in storage
          setToken(newToken, !!getRememberMe());
          return true;
        }
      }
      return false;
    }

    return true;
  } catch (error) {
    console.error('Token refresh failed:', error);
    return false;
  }
};

export const getUserEmailFromToken = (): string | null => {
  const token = getToken();
  if (!token) return null;

  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    return decodedToken.email || null;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

export const getUserNameFromToken = (): string | null => {
  const token = getToken();
  if (!token) return null;

  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    return decodedToken.full_name || null;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

export const generateInitials = (name: string): string => {
  const names = name.split(' ').filter((n) => n.length > 0);
  if (names.length === 0) return '';
  if (names.length === 1) {
    return names[0].length > 1
      ? `${names[0][0]}${names[0][names[0].length - 1]}`.toUpperCase()
      : names[0][0].toUpperCase();
  }
  return `${names[0][0]}${names[names.length - 1][0]}`.toUpperCase();
};

export const getUserIdFromToken = (): number | null => {
  const token = getToken();
  if (!token) return null;

  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    // Convert the string ID back to number
    return decodedToken.sub ? parseInt(decodedToken.sub, 10) : null;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

export const getUserTypeFromToken = (): string | null => {
  const token = getToken();
  if (!token) return null;

  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    return decodedToken.user_type || null;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

export const getLoginRoute = (): string | null => {
  const userType = getUserTypeFromToken();
  switch (userType) {
    case UserType.ADMIN:
    case UserType.HOA_MANAGER_ADMIN:
    case UserType.HOA_MANAGER_TEAM_MEMBER:
      return '/home';
    case UserType.HOA_HOME_OWNER:
    case UserType.HOA_BOARD_MEMBER:
    case UserType.ANON:
      return '/home';
    default:
      console.log('no user type match :(' + userType);
      return null;
  }
};

export const hasManagerPermission = (): boolean => {
  const userType = getUserTypeFromToken();
  const managerRoles: Array<string> = [
    UserType.ADMIN,
    UserType.HOA_MANAGER_ADMIN,
    UserType.HOA_MANAGER_TEAM_MEMBER,
  ];

  return userType !== null && managerRoles.includes(userType);
};
